<template>
	<div style="background: #f6f7f9;">
		<homeheader style="width: 100%"></homeheader>

		<div class="container" style="display: flex;margin-right: auto;
		margin-left: auto;
		background: #f6f7f9;
    max-width: 101.25rem;">
			<tree title="相关链接" type="4" @handlerCurrent="getStudentList"></tree>
			<div class="problemview" style=" background: #feffff;
      width: 75.625rem;">
				<div class="listview">
					<el-table :data="tableData" style="width: 100%">
						<el-table-column prop="name" label="链接名称">
							<template slot-scope="scope">
								<!-- <a :href="scope.row.name" target="target=_blank"> -->
									<span style="margin-left: 10px">{{ scope.row.name }}</span>
								<!-- </a> -->
							</template>
						</el-table-column>
						<el-table-column prop="content" label="链接">
							<template slot-scope="scope">
								<span style="margin-left: 10px;cursor:pointer" @click="addURL(scope.row.content)" >{{ scope.row.content }}</span>
								<!-- <a :href="scope.row.content" target="target=_blank">
									<span style="margin-left: 10px">{{ scope.row.content }}</span>
								</a> -->
							</template>
						</el-table-column>
						
					</el-table>
				</div>
			</div>
		</div>
		<homefooter style="margin-top: 22px;"></homefooter>
	</div>
</template>

<script>
	import tree from '@/components/tree/index'

	// import homeheader from "./homeheader.vue";
	import homefooter from "./homefooter.vue";
	import {
		linkChild,
		aboutlink
	} from '@/api/home'
	// import decrypt from '@/utils/crypto'

	export default {
		components: {
			
			homeheader: () => import("./homeheader.vue"),
			homefooter,
			tree
		},
		data() {
			return {
				search: {
					id: '',
				},
				page: {
					current: 1,
					size: 10,
					total: 0,
				},
				tableData: [],

				audioUrl: '',
				audioVisible: false,
			}
		},
		mounted() {
			// this.getList()
			aboutlink().then(res => {
				this.search.id = res.data[0].id
				this.getList(1)
			})
			window.scrollTo(0,0)
		},
		methods: {
			addURL(url) {
				window.open(url);
			},
			getList(page) {
				page ? (this.page.current = page) : ''

				linkChild(this.search).then(
					(res) => {
						this.tableData = res.data
						// this.page.total = res.data.total
					}
				)
			},
			getStudentList(e) {
				this.search.id = e.id
				this.getList(1)
			},
			handleCurrentChange(e) {
				this.getList(e)
			},
			handleSizeChange(e) {
				this.getList()
			},
			look(row) {
				this.audioUrl = decrypt.decrypt(row.fileUrl)
				this.audioVisible = true
			},
			handleRightClick(event) {
				event.preventDefault()
			},
			hidedialog() {
				var video = document.getElementById('video')
				video.pause()
				this.audioUrl = ''
				this.audioVisible = false
			},
			noModel() {
				this.$message('功能暂未开放，敬请期待')
			},
		},
	}
</script>

<style scoped lang="scss">
	.container {
		margin-right: auto;
		margin-left: auto;
		background: #f6f7f9;
		padding: 10px 20px;
		display: flex;

		
	}
.problemview {
      // margin-top: 1rem;
      background: #feffff;
      width: 75.625rem;
      margin-bottom: 1.25rem;

      .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #feffff;
        margin-top: 2rem;
        padding-bottom: 2.875rem;
      }

      .listview {
        .listitemview {
          display: flex;
          padding-left: 2.8125rem;
          padding-right: 2.8125rem;

          .listitemview_img {
            width: 28.375rem;
            height: 20.3125rem;
            padding: 3.4375rem 0;
          }

          .listitemview_content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 3.4375rem 0;
            width: 44.4375rem;

            .top {
              display: flex;
              justify-content: space-between;

              span {
                font-size: 40px;
                font-family: PingFang SC-Bold, PingFang SC;
                font-weight: bold;
                color: #191919;
                display: block;
                line-height: 4.5625rem;
              }

              .top_img {
                width: 4.625rem;
                height: 4.625rem;
              }
            }

            .bottom {
              .bottom_content {
                font-size: 16px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #191919;

                span {
                  color: #999;
                }
              }
            }
          }
        }
      }
    }