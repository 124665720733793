import Vue from 'vue'
import Router from 'vue-router'

import homepage from '../components/homepage.vue'
import newslist from '../components/newslist.vue'
import certificate from '../components/certificate.vue'
import certificatelist from '../components/certificatelist.vue'
import successfulcase from '../components/successfulcase.vue'
import faceback from '../components/faceback.vue'
import business from '../components/business.vue'
import desc from '../components/desc.vue'

const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


Vue.use(Router)

export default new Router({
	mode:'history',
	
	routes: [
		
		{
			path: '/',
			name: '首页',
			component: () => import('../components/homepage.vue'),
		},
		{
			path: '/newslist',
			name: '新闻',
			component: () => import('../components/newslist.vue'),

		},
		{
			path: '/certificate',
			name: '证书',
			component: () => import('../components/certificate.vue'),
		
		},
		{
			path: '/certificatelist',
			name: '证书列表',
			component: () => import('../components/certificatelist.vue'),
		
		},
		{
			path: '/successfulcase',
			name: '成功案例',
			component: () => import('../components/successfulcase.vue'),
		
		},// problem
		{
			path: '/problem',
			name: '常见问题',
			component: () => import('../components/problem.vue'),

		},
		// {
		// 	path: '/about',
		// 	name: '相关链接',
		// 	component: () => import('../components/about.vue'),
		
		// },
		{
			path: '/search',
			name: '搜索',
			component: () => import('../components/search.vue'),
		
		},
		{
			path: '/about',
			name: '企业责任',
			component: () => import('../components/about.vue'),
		
		},
		{
			path: '/faceback',
			name: '意见反馈',
			component: () => import('../components/faceback.vue'),
		
		},
		{
			path: '/desc',
			name: '详情',
			component: () => import('../components/desc.vue'),
		},
		{
			path: '/business',
			name: '相关链接',
			component: () => import('../components/business.vue'),
		},
		
		{
			path: '/newdesc',
			name: '新闻详情',
			component: () => import('../components/newdesc.vue'),
		},
		
	],
})
// router.push('/home');
// const VueRouterPush = Router.prototype.push
// Router.prototype.push = function push(to) {
//     return VueRouterPush.call(this, to).catch(err => err)
// }
// export default router
