<template>
	<div class="main">
		<homeheader style="width: 100%"></homeheader>
		<div class="showmian">
			<div  :class="form ? '' : 'h100'" style="width: 86.25rem;margin-left: auto;margin-right: auto;padding: 30px;background-color: #fff;">
				<div
					style="width: 84.25rem;margin-left: auto;margin-right: auto;text-align: center;background-color: #fff;">
					<div style="font-family: 黑体; font-size: 24px;color:#333 ;">{{ data.title2 }}</div>
				</div>
				<div style="height: 37px;"></div>
				<div class="topHeader"
					style="width: 84.25rem;margin-left: auto;margin-right: auto;background-color: #fff;margin-bottom: 20px;">
					<div class="left">
						<span>{{ data.update_time1 }}</span>
						<span style="margin-left: 52px;">来源：{{ data.from }}</span>
					</div>
					<div class="text_fx">
						<div style="display: flex;" @click="addprint">
							<img style="width: 20px;height: 20px;margin-right: 5px;" src="../assets/dayin.png" alt="">
							<div>打印</div>
						</div>
						<div style="margin-left: 45px; display: flex;" @click="addShare">
							<img style="width: 20px;height: 20px;margin-right: 5px;" src="../assets/share.png" alt="">
							<div>分享</div>
						</div>
					</div>
				</div>
				<div v-html="form"></div>
				<div v-for="(item,index) in data.file">
					<div @click="fileclick(item)" style="cursor: pointer;color: blue;margin-top: 5px;">{{item.name}}</div>
				</div>
			</div>

		</div>


		<homefooter></homefooter>

	</div>
</template>

<script>
// import homeheader from "./homeheader.vue";
import homefooter from "./homefooter.vue";
import {
	getarticle
} from "@/api/home";

export default {
	name: "business",
	props: {
		msg: String,
	},
	components: {
		homeheader: () => import("./homeheader.vue"),
		homefooter,
	},
	data() {
		return {
			form: '',
			data: {}
		}
	},
	created() {

	},
	mounted() {

		let newid = this.$route.query.id;
		getarticle({
			"id": newid
		}).then(res => {
			console.log(res);
			// res.data.content = res.data.content?.replace(
			// 	/img/gi,
			// 	'img style="width: 100%"'
			// );
			// res.data.content = res.data.content?.replace(
			// 	/video/gi,
			// 	'video style="width: 100%"'
			// );
			this.data = res.data;
			this.form = res.data.content;

		})
	},
	methods: {
		fileclick(item){
			window.open(item.officeweb_address);
		},
		addShare() {
			let url = window.location.href // 后台接口返回的链接地址
			let inputNode = document.createElement('input')  // 创建input
			inputNode.value = url // 赋值给 input 值
			document.body.appendChild(inputNode) // 插入进去
			inputNode.select() // 选择对象
			document.execCommand('Copy') // 原生调用执行浏览器复制命令
			inputNode.className = 'oInput'
			inputNode.style.display = 'none' // 隐藏
			this.$message.success('复制分享链接成功')
		},
		addprint() {
			window.print()
		},
		clickhome() {
			this.$router.replace("/")
		}
	}
}
</script>

<style lang="scss" scoped>
.h100 {
	height: 100vh;
}
.showmian {
	background-color: #f5f5f5;
	// height: 100vh;
	font-family: 宋体;
	// font-family: ;
	// font-weight：normal;
}

.topHeader {
	margin-right: auto;
	margin-left: auto;
	max-width: 101.25rem;
	display: flex;
	justify-content: space-between;
	height: 42px;
	// line-height: 40px;
	font-size: 16px;
	border-bottom: 1px solid #d8d8d8;
	color: #5c5c5c;

}

.text_fx {
	display: flex;
}
</style>