<template>
	<div class="listmain">
		<homeheader style="width: 100%"></homeheader>
		<div class="Navigation">
			<i @click="clickhome" class="el-icon-s-home"></i>
			<p>></p>
			<p style="color: #266fe8;">更多</p>
		</div>

		<div class="listview">
			<div class="problemleft">
				<div v-for="(item, index) in titlearry" :key="index" @click="itemclick(index)"
					:class="['problemleftitem', slected == index ? 'on' : '']">
					<img style="width: 14px; height: 15px; display: block" :src="slected == index ? img : img1"
						alt="" />
					{{ item.title }}
				</div>
			</div>
			<div class="listbackview">
				<div class="listitem" @click="descclick(item)" v-for="(item, index) in right" :key="index">
					<p class="listitemtop">{{item.title}}</p>
					<p class="listitemmain"></p>
					<div class="listitem_boom">
						<div class="sourc">
							<span style="vertical-align: middle"></span>
							<img style="vertical-align: middle" src="../assets/soure.png" alt="" />
							<span style="vertical-align: middle"> 来源：{{item.from}}</span>
						</div>
						<div class="date">
							<img style="vertical-align: middle" src="../assets/date.png" alt="" />
							<span style="vertical-align: middle">{{item.update_time}}</span>
						</div>
					</div>
					<div class="line"></div>
				</div>
				<div class="pagination" v-if="page.total>0">
					<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="page.offset"
						layout=" sizes, prev, pager, next, jumper" :total="page.total">
					</el-pagination>
				</div>
			</div>
		</div>

		<homefooter style="width: 100%"></homefooter>
	</div>
</template>

<script>
	import img from "@/assets/choose.png";
	import img1 from "@/assets/choose1.png";
	import soure from "@/assets/soure.png";
	import date from "@/assets/date.png";
	import homefooter from "./homefooter.vue";
	
	import {
		indexlayout,
		news_info,
		news_detail,
		getarticle
	} from "@/api/home";
	export default {
		name: "newslist",
		props: {
			msg: String,
		},
		components: {
			homeheader: () => import("./homeheader.vue"),
			homefooter,
		},
		data() {
			return {
				soure: soure,
				date: date,
				img: img,
				img1: img1,
				currentPage: 1,
				page: {
					offset: 10,
					page: 0,
					total: 0,
				},
				slected: "",
				right: [],
				titlearry: ["行业要闻", "通知公告", "历史时刻", "事故快讯", "市政交通"],
			};
		},
		created() {
			this.getdata();
		},
		methods: {
			getdata() {
				news_info(this.page).then((res) => {
					console.log(res);
					this.titlearry = res.data.left
					this.right = res.data.data
					this.page.total = res.data.count
				});
			},
			descclick(item) {


				// console.log("e==========", e);
				getarticle({
					"id": item.article_id
				}).then(res => {
					console.log(res);
					// 0.暂未开放 1.列表 2.内容 3.外链 4.内链
					if (res.data.type == 2) {
						this.$router.push({
							name: "详情",
							query: {
								id: item.article_id,
							},
						});
					}
					if (res.data.type == 3) {
						window.open(res.data.link);
					}
				})
			},
			itemclick(index) {
				console.log(index);
				this.slected = index;
				this.page.id = this.titlearry[index].id
				this.getdata()
			},
			clickhome() {
				this.$router.replace("/");
			},
			handleSizeChange(e) {
				console.log('111111111', e);
				this.page.offset = e
				this.getdata()
			},
			handleCurrentChange(e) {
				console.log('2222222222', e);
				this.page.page = e
				this.getdata()
			},
		},
	};
</script>

<style scoped lang="scss">
	.pagination {
		display: flex;
		align-items: center;
		justify-content: center;
		// background-color: #FEFFFF;
		margin-top: 2rem;
		padding: 0px 10px 10px 10px;
	}

	.listmain {
		background: #f6f7f9;
		margin-right: auto;
		margin-left: auto;
		max-width: 120rem;

		.Navigation {
			margin-right: auto;
			margin-left: auto;
			max-width: 101.25rem;
			display: flex;
			height: 57px;
			align-items: center;

			p {
				font-size: 14px;
				font-family: PingFang SC-Regular, PingFang SC;
				font-weight: 400;
				color: #000000;
				margin-left: 5px;
			}
		}

		.listview {
			display: flex;
			max-width: 101.25rem;
			margin-right: auto;
			margin-left: auto;
			margin-bottom: 1.25rem;

			// .problemleft :hover.problemleftitem {
			// 	background-color: #266FE8;
			// 	color: #fff;

			// }

			// .problemleftitem :hover {
			// 	background-color: #266FE8;
			// 	color: #fff;
			// }

			.problemleft {
				padding-top: 19px;
				width: 390px;
				height: 417px;
				font-size: 16px;
				font-family: PingFang SC-Regular, PingFang SC;
				font-weight: 400;
				color: #191919;
				background: #fff;
				margin-right: 9px;

				.problemleftitem {
					display: flex;
					height: 47px;
					padding-left: 49px;
					line-height: 47px;
					text-align: left;
					position: relative;
					cursor: pointer;
					font-size: 16px;
					font-family: PingFang SC-Regular, PingFang SC;
					font-weight: 400;
					color: #191919;

					img {
						margin-top: auto;
						margin-bottom: auto;
						margin-right: 4px;
					}

					&.on {
						background-color: #266fe8;
						color: #fff;
					}
				}
			}

			.listbackview {
				padding: 2.4375rem;
				background: #fff;
				width: 1210px;

				.listitem {
					.listitemtop {
						font-size: 1.125rem;
						font-family: PingFang SC-Regular, PingFang SC;
						font-weight: 400;
						color: #191919;
					}

					.listitemmain {
						font-size: 1rem;
						font-family: PingFang SC-Regular, PingFang SC;
						font-weight: 400;
						color: #999999;
					}

					.line {
						margin-top: 1.25rem;
						background-color: #e9e9e9;
						height: 1px;
					}

					.listitem_boom {
						display: flex;
						font-size: 0.875rem;
						font-family: PingFang SC-Regular, PingFang SC;
						font-weight: 400;
						color: #1353a7;

						.date {
							span {
								color: #1353a7;
								margin-left: 2px;

								font-size: 14px;
								font-family: PingFang SC-Regular, PingFang SC;
								font-weight: 400;
							}

							margin-left: 30px;
							margin-right: 0.5rem;
						}

						.sourc {
							margin-top: auto;
							margin-bottom: auto;
							align-items: center;
							text-align: center;

							span {
								color: #1353a7;
								// margin-left: 2px;

								font-size: 14px;
								font-family: PingFang SC-Regular, PingFang SC;
								font-weight: 400;
							}
						}
					}
				}
			}
		}
	}
</style>