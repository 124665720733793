<template>
	<div class="header">
		<div class="contair" @click.stop="changeloginShow">
			<div class="header-top">
				<!-- //左边 -->
				<div class="top_left">
					<a href="">
						<img class="logoimage" src="../assets/logo.png" alt="" />
					</a>
					<div class="title">
						<p>北京晓瑞安全科技有限公司</p>
						<span>LUCKYRISE SAFTY TECH（BEIJING）Co.,Ltd</span>
					</div>
				</div>
				<!-- //中间 -->
				<div class="top_con">
					<img src="../assets/head_two.png" alt="" />
				</div>
				<!-- 右侧 -->
				<div class="top_right" style="margin-top: 20px;">
					<div class="one" @click="openuser">
						<div class="login" @click="openuser">
							<img src="../assets/head_user.png" alt="" />
							<span st>登录/注册</span>
							<!-- <i style="cursor: pointer; font-size: 20px; margin-left: 6px" v-if="!loginShow"
								class="el-icon-arrow-down" @click.stop="loginShow = !loginShow"></i> -->
							<!-- <i style="cursor: pointer; font-size: 20px; margin-left: 6px" v-if="loginShow"
								class="el-icon-arrow-up" @click.stop="loginShow = !loginShow"></i> -->
						</div>
						<!-- <div class="logins" v-if="loginShow">
							<div @click.stop="gourl(item, index)" class="items" v-for="(item, index) in selectList"
								:key="index" @mousemove="changequickshow">
								{{ item.name }}
							</div>
							
						</div> -->
					</div>
					<!-- <div class="one">
						<div class="login" @click.stop="gourl(12, 2)">
							<img src="../assets/head_anmin.png" alt="" />
							<span>平台管理</span>
							<span></span>
						</div>
						<div v-show="quickshow" id="login_containers" style="text-align: center"></div>
					</div> -->
					<div class="three">
						<el-input class="searchinput" v-model="search" type="text" placeholder="请输入关键词"></el-input>
						<div class="text">
							<img src="../assets/head_search.png" alt="" />
							<span>搜索</span>
						</div>
					</div>
				</div>
				<!-- <div>
					<a style="margin-top: 1.9375rem;margin-bottom: 1.375rem;" href="">
						<img class="logoimage" src="../assets/logo.png" alt="">
					</a>
				</div>
				<div class="right-search">
					<div class="right-searchtop">
						<input class="searchinput" v-model="search" type="text" placeholder="    请输入关键词">
						<button @click="searchclick" class="searchbtn">
							<i class="el-icon-search"></i>搜索</button>
					</div>

					<div class="right-search-boom">
						<button @click="loginclick" class="right-search-boombtn" style="margin-left: 0px;"> <i
								class="iconitem"></i> 登录注册</button>
						<button @click="clicknav(zhengshu)" class="right-search-boombtn" style="margin-right: 0px;"><i
								class="iconitemsearch"></i>证书查询</button>
					</div>
				</div> -->
			</div>
			<div class="header-boom">
				<ul class="nav">
					<li v-for="(item, index) in navtitle" :key="index" @click="clicknav(item)">
						<div @mouseover="geeks(index)">{{ item.title }}</div>
						<ul v-show="index == isindex ? true : false" class="dropdown-menu" @mouseleave="geeks(99999)"
							v-if="item.child">
							<li class="dropdown-item" v-for="(items, indexs) in item.child" :key="indexs">
								<div @click="clicktwo(items)">
									{{ items.title }}
								</div>
								<!-- <router-link :to="{name:items.name}">{{items.name}} </router-link> -->
							</li>
						</ul>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import {
	MessageBox,
	Message
} from 'element-ui'
import store from "@/store/index";
import {
	indexlayout,
	getarticle
} from "@/api/home";
export default {
	name: "homeheader",
	props: {
		msg: String,
		list: Array
	},
	data() {
		return {
			quickshow: false,
			loginShow: false,
			selectList: [{
				name: "企业管理"
			}, {
				name: "个人用户"
			}],
			search: "",
			dialogVisible: false,
			isindex: null,
			zhengshu: {
				name: "证书查询",
			},
			navtitle: [{
				name: "首页",
			},
			{
				name: "产品与服务",
				children: [{
					name: "合规性审核服务",
				},
				{
					name: "技术审核服务",
				},
				{
					name: "内部管理提升咨询服务",
				},
				{
					name: "双重预防机制构建咨询服务",
				},
				{
					name: "特殊作业信息化管理",
				},
				],
			},
			{
				name: "培训考试",
				children: [{
					name: "在线考试",
				},
				{
					name: "教育培训",
				},
				{
					name: "直播课堂",
				},
				{
					name: "课程资源",
				},
				{
					name: "资格考试辅导",
				},
				],
			},
			{
				name: "数据服务",
				children: [{
					name: "xxxxxx",
				},
				{
					name: "xxxxxx",
				},
				{
					name: "xxxxxx",
				},
				{
					name: "xxxxxx",
				},
				{
					name: "xxxxxx",
				},
				],
			},
			{
				name: "资源中心",
				children: [{
					name: "法律法规",
				},
				{
					name: "标准规范",
				},
				{
					name: "事故案例",
				},
				{
					name: "百科知识",
				},
				{
					name: "跳瘙市场",
				},
				],
			},
			{
				name: "问与答",
			},
			{
				name: "成功案例",
			},
			{
				name: "关于我们",
				children: [{
					name: "关于我们",
				},
				{
					name: "企业责任",
				},
				{
					name: "合作加盟",
				},
				],
			},
			],
			openUrl: "",
		};
	},
	// 监听属性
	watch: {
		$route(to, from) {
			//   let code = this.$route.query.code;
			//   let loginPlatform = "";
			//   let href = this.openUrl;
			//   if (href.indexOf("name=pcUser") >= 0) {
			//     loginPlatform = 3;
			//   } else {
			//     if (href.indexOf("edu.bjxraq") !== -1) {
			//       href.indexOf("safety_admin") >= 0 ? (loginPlatform = 1) : (loginPlatform = 2);
			//     }
			//   }
			//   if (code) {
			//     let data = {
			//       code: code,
			//       loginPlatform: loginPlatform,
			//       loginType: 3,
			//       username: "微信用户",
			//       password: "123456",
			//     };
			//     store.dispatch("user/login", data).then((res) => {
			//       if (res.code == 0) {
			//         this.$message({
			//           message: "登录成功",
			//           type: "success",
			//         });
			//         redict(loginPlatform);
			//         // window.location.href = this.openUrl;
			//         // window.open(this.openUrl);
			//         // if (this.$route.query.name) {
			//         // } else {
			//         //   this.$router.push({
			//         //     path: "/",
			//         //   });
			//         // }
			//       }
			//       if (res.code == 100001) {
			//         this.$message({
			//           message: "该微信用户没有绑定请先绑定",
			//           type: "success",
			//         });
			//         window.open(this.openUrl);
			//         // this.wxunionid = res.data.unionid;
			//         // this.wxopenid = res.data.openid;
			//         // this.tabList[0].show = false;
			//         // this.tabList[1].show = true;
			//         // this.binding = "去绑定";
			//         // this.quickshow02 = true;
			//         // this.quickshow = false;
			//       }
			//       if (res.code == 100002) {
			//         window.open(this.openUrl);
			//         // this.$message({
			//         //   message: "该微信用户没有绑定请先绑定",
			//         //   type: "success",
			//         // });
			//         // this.$confirm(`您的注册申请因${res.data}已驳回`, "提示", {
			//         //   confirmButtonText: "重新申请",
			//         //   cancelButtonText: "取消",
			//         //   type: "warning",
			//         // })
			//         //   .then(() => {
			//         //     this.typeshow = true;
			//         //   })
			//         //   .catch(() => {
			//         //     this.$message({
			//         //       type: "info",
			//         //       message: "已取消",
			//         //     });
			//         //   });
			//       }
			//     });
			//   }
		},
	},
	created() {
		this.getdata()
	},
	beforeMount() {
		this.getdata()
	},
	methods: {
		openuser() {
			this.openUrl = "https://edu.bjxraq.com/safety_business/#/login?name=pcUser";
			window.open(this.openUrl);
		},
		getdata() {
			indexlayout().then(res => {
				console.log(res);
				console.log("表头创建了1111111111111");
				this.navtitle = res.data[0].child;
			})
		},
		changequickshow() { },
		get_wx_qrcode(iphone) {
			//从微信的开发文档不难看出回调域名需要经过encodeURIComponent处理
			let call_back_domain_name = window.location.href,
				domain_name_length = call_back_domain_name.indexOf("#") - 1;
			// DDNS = call_back_domain_name.substring(0, domain_name_length),
			let DDNS = "";
			if (iphone) {
				DDNS = `https://edu.bjxraq.com/safety_business/#/login?name=pcUser&userId=${iphone}`;
			} else {
				DDNS = call_back_domain_name;
			}

			//   let DDNS_resolution = encodeURIComponent(DDNS);
			let DDNS_resolution = encodeURIComponent(this.openUrl);
			console.log("DDNS_resolution", DDNS_resolution);
			var obj = new WxLogin({
				self_redirect: false,
				id: "login_containers", //需要承载二维码的容器id
				appid: "wx0e345a2763b2accf",
				scope: "snsapi_login", //网页授权，目前网页只有这一种方式，静默授权
				redirect_uri: DDNS_resolution, //回调域名
				state: Math.random(),
				style: "black",
				href: "",
			});
		},
		gourl(item, idx) {
			console.log(idx);

			if (idx == 0) {
				// 企业端

				this.openUrl = this.openUrl = "https://edu.bjxraq.com/safety_business/#/login";
			} else if (idx == 1) {
				// pcuser

				this.openUrl = "https://edu.bjxraq.com/safety_business/#/login?name=pcUser";
			} else {
				this.openUrl = "https://edu.bjxraq.com/safety_admin/#/login";
			}
			console.log(this.openUrl);
			this.get_wx_qrcode();
			this.quickshow = !this.quickshow;
		},
		changeloginShow() {
			console.log(1111);
			this.quickshow = false;
		},
		geeks(e) {
			console.log(e, '1111111112');
			this.isindex = e;
			this.dialogVisible = true;
		},
		loginclick() {
			this.dialogVisible = true;
		},

		handleClose(done) {
			this.dialogVisible = false;
		},
		searchclick() {
			this.$router.push({
				name: "搜索",
			});
		},
		clicktwo(item) {
			getarticle({
				"id": item.article_id
			}).then(res => {
				console.log(res);
				if (res.code == 400) {
					console.log("code=====1111111========");
					Message({
						message: res.message,
						type: 'error',
						duration: 1000
					})
				} else {
					if (res.data.type == 2) {
						this.$router.push({
							path: '/newdesc',
							query: {
								id: item.article_id,
							},
						});
					}
					if (res.data.type == 3) {
						window.open(res.data.link);
					}
				}
				// 0.暂未开放 1.列表 2.内容 3.外链 4.内链

			})
			// if(item.){

			// }
		},
		clicknav(item) {
			if (item.title == "问与答") {


				this.$message('尊敬的用户，本模块正在进行内测，敬请期待！感谢您的关注……');

			}
			if (item.title == "企业责任") {
				{
					// this.$router.push({
					//   name: "企业责任",
					// });
				}
			} else if (item.title == "证书查询") {
				{
					// this.$router.push({
					//   name: "证书",
					// });
				}
			} else if (item.title == "首页") {
				const loading = this.$loading({
					lock: true,
					text: "",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});
				{
					this.$router.push({
						name: "首页",
					});
				}
				loading.close()
			} else if (item.title == "成功案例") {
				{
					this.$router.push({
						name: "成功案例",
					});
				}
			} else if (item.title == "问与答") {
				{

				}
			} else if (item.title == "关于我们") {

				getarticle({
					"id": item.article_id
				}).then(res => {
					console.log(res);
					if (res.code == 400) {
						console.log("code=====1111111========");
						Message({
							message: res.message,
							type: 'error',
							duration: 1000
						})
					}
					// 0.暂未开放 1.列表 2.内容 3.外链 4.内链
					if (res.data.type == 2) {
						this.$router.push({
							path: '/newdesc',
							query: {
								id: item.article_id,
							},
						});
					}
					if (res.data.type == 3) {
						window.open(res.data.link);
					}
				})
				// if(item.){

				// }

			} else {
				const loading = this.$loading({
					lock: true,
					text: "",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});
				this.$router.push("/");
				loading.close()
			}
		},
	},
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__body {
	padding: 0;
}

.iconitem {
	display: inline-block;
	width: 0.8125rem;
	height: 0.8125rem;
	background-image: url(../assets/账号登录.png);
	background-position: center;
	background-size: 13px 13px;
	background-repeat: no-repeat;
	margin-right: 5px;
}

.iconitemsearch {
	display: inline-block;
	width: 15px;
	height: 15px;
	background-image: url(../assets/查询.png);
	background-position: center;
	background-size: 15px 15px;
	background-repeat: no-repeat;
	margin-right: 5px;
}

.contair {
	display: flex;
	// max-width: 101.25rem;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	margin-right: auto;
	margin-left: auto;

	.header-boom {
		background-color: #266fe8;
		width: 100%;

		.nav {
			list-style: none;
			padding-left: 0;
			display: flex;
			justify-content: space-around;
			padding: 0 5.625rem;
			margin: 0;

			.dropdown-menu {
				background: #e7f4fe;
				border-radius: 4px 4px 4px 4px;
				position: absolute;
				z-index: 1000;
				min-width: 10rem;
				padding: 0;
				font-size: 1rem;
				color: #212529;
				text-align: left;
				list-style: none;
				padding-top: 1rem;
				padding-bottom: 1rem;
				min-width: 200px;
				background-clip: padding-box;
				border: 1px solid rgba(0, 0, 0, 0.15);
				border-top-color: rgba(0, 0, 0, 0.15);
				border-right-color: rgba(0, 0, 0, 0.15);
				border-bottom-color: rgba(0, 0, 0, 0.15);
				border-left-color: rgba(0, 0, 0, 0.15);
				border-radius: 0.25rem;

				.dropdown-item {
					width: 100%;
					list-style-type: none;
					margin-right: 0.9375rem;
					// padding-right: 0.625rem;
					clear: both;
					font-weight: 400;
					color: #000000;
					text-align: inherit;
					text-decoration: none;
					white-space: nowrap;
					border: 0;
					font-size: 16px;
					font-family: PingFang SC-Bold, PingFang SC;
					font-weight: bold;

					&:hover {
						// background-color: rgba(255, 255, 255, .2);
						color: #266fe8;
					}
				}

				a {
					// padding-left: .5rem;
				}
			}
		}

		.nav li {
			// float: left;
			color: #fff;
			font-size: 1.25rem;
			font-family: PingFang SC-Bold, PingFang SC;
			font-weight: bold;
			// width: 12.5%;
		}

		.nav a {
			color: #fff;
			line-height: 1.75rem;
			display: block;
			padding-left: 2rem;
			padding-bottom: 1.5625rem;
			padding-top: 1rem;
			text-decoration: none;
		}

		.nav div {
			// color: #fff;
			line-height: 1.75rem;
			display: block;
			padding-left: 2rem;
			padding-bottom: 1.5625rem;
			padding-top: 1rem;
			text-decoration: none;
			cursor: pointer;
		}
	}

	.header-top {
		display: flex;
		height: 5rem;
		width: 100%;
		justify-content: space-around;
		padding-top: 2rem !important;
		padding-bottom: 2rem !important;
		background: url("../assets/head_bc.png") no-repeat;
		background-size: 100%;
		align-items: center;
		padding: 0 1.25rem
			/* 20/16 */
		;

		.top_left {
			display: flex;

			.logoimage {
				width: 5.5625rem
					/* 89/16 */
				;
				height: 5.5625rem
					/* 89/16 */
				;
			}

			.title {
				margin-left: 1.125rem
					/* 18/16 */
				;

				p {
					font-size: 2.6875rem
						/* 43/16 */
					;
					font-family: Microsoft YaHei-Bold, Microsoft YaHei;
					font-weight: bold;
					color: #373738;
					margin: 0;
				}

				span {
					font-size: 1.6875rem
						/* 27/16 */
					;
					font-family: PingFang SC-Regular, PingFang SC;
					font-weight: 400;
					color: #041130;
				}
			}
		}

		.top_con {
			img {
				width: 21.375rem
					/* 342/16 */
				;
				height: 6.125rem
					/* 98/16 */
				;
			}
		}

		.top_right {
			display: flex;

			.one {
				position: relative;
				margin-right: 0.625rem
					/* 10/16 */
				;

				.login {
					//   width: 8rem /* 120/16 */;
					padding: 0px 14px;
					height: 2rem;
					// background: #f5f5f5;
					border-radius: 0.1875rem;
					opacity: 1;

					display: flex;
					align-items: center;
					justify-content: space-around;
					cursor: pointer;

					img {
						width: 12.52px;
						height: 13.39px;
						margin-right: 6px;
					}

					span {
						font-size: 14px;
						font-family: PingFang SC-Regular, PingFang SC;
						font-weight: 400;
						color: #191919;
					}
				}

				.logins {
					position: absolute;
					// bottom: -20px;
					top: 2.5rem;
					width: 7.5rem;
					height: 5.25rem;
					background: #ffffff;
					box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
					border-radius: 3px 3px 3px 3px;
					opacity: 1;
					padding-top: 0.75rem;
					box-sizing: border-box;

					.items {
						height: 45%;
						padding-left: 1.6875rem;
						box-sizing: border-box;
						cursor: pointer;
						font-size: 0.875rem;

						&:hover {
							font-size: 0.875rem;
							font-family: PingFang SC-Bold, PingFang SC;
							font-weight: bold;
							color: #266fe8;
							background: rgba(38, 111, 232, 0.1);
							border-left: 3px solid #266fe8;
						}
					}
				}
			}

			.three {
				width: 16rem;
				height: 2rem;
				background: #feffff;
				border-radius: 0.1875rem;
				opacity: 1;
				display: flex;

				.searchinput {
					height: 100%;
					width: 16.25rem;
				}

				/deep/ .el-input__inner {
					height: 100%;
				}

				.text {
					display: flex;
					width: 5.375rem;
					height: 2rem;
					background: #266fe8;
					border-radius: 0px 3px 3px 0px;
					opacity: 1;
					align-items: center;
					cursor: pointer;

					img {
						width: 0.75rem;
						height: 0.75rem;
						margin: 0 3px;
					}

					span {
						font-size: 0.875rem;
						font-family: PingFang SC-Regular, PingFang SC;
						font-weight: 400;
						color: #ffffff;
					}
				}
			}
		}
	}
}

.boombtn {
	width: 50%;
	background-color: #fff;
	color: #1d53bf;
	border-color: transparent;
	border: 0;
	outline: none;
	border-right: 1px solid #212529;
	border-top: 1px solid #212529;
}

#login_containers {
	//   width: 146px;
	//   height: 114px;
	position: absolute;
	z-index: 100;
	// bottom: -20px;
	top: 2.5rem;
	background: #f6f6f6;

	// .weui_icon_msg: before
	/deep/ iframe {
		// width: 100% !important;
		// height: 100% !important;
	}

	/deep/.weui_icon_msg:before {
		font-size: 12px;
	}
}
</style>