import axios from 'axios'
import {
  MessageBox,
  Message
} from 'element-ui'
import Vue from 'vue'
import store from '@/store'
import {
  getToken,
  removeToken
} from '@/utils/auth'
import JSONbig from 'json-bigint' //解决超过 16 位数字精度丢失问题


axios.defaults.transformResponse = [
  function (data) {
    const json = JSONbig({
      storeAsString: true,
    });
    const res = json.parse(data);
    return res;
  },
];
// create an axios instance
const service = axios.create({
  baseURL: 'https://www.bjxraq.com/',
   // http://soft-web-index.shaokepei.top/
   // url = base url + request url
  // baseURL: '/dev', // url = base url + request url
  // baseURL:'https://edu.bjxraq.com/', // 打包

  timeout: 500000, // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    console.log(config.url)
    if (
      config.url == "questionlib/api/standard/specification/export/file" ||
      config.url == "questionlib/api/standard/specification/data/export" ||
      config.url == "questionlib/api/standard/specification/export/all/file" ||
      config.url == "questionlib/api/law/regulations/export/all/file" ||
      config.url == "questionlib/api/law/regulations/export/file" ||
      config.url == "questionlib/api/law/regulations/data/export" ||
      config.url == "questionlib/api/dangerous/goods/export/all/file" ||
      config.url == "questionlib/api/dangerous/goods/export/file" ||
      config.url == "questionlib/api/dangerous/goods/data/export" ||
      config.url == "exam/api/courseEvaluateRecord/admin/batch/export" ||
      config.url == 'exam/api/admin/integral/exportExcel' ||
      config.url == 'exam/api/coursewarelearnrecord/admin/download/record' ||
      config.url == 'exam/api/invoicerecord/admin/invoice/export'

    ) {
      console.log("---")
      config.responseType = "arraybuffer"
    }
    // do something before request is sent

    // if (store.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    //   config.headers['X-Token'] = getToken()
    // }
    config.headers['loginPlatform'] = store.getters.loginPlatform
    config.headers['Authorization'] = getToken()
    config.headers['Content-Type'] = "application/json;"
    // config.headers['Content-Type'] = "application/octet-stream"
    config.headers['Access-Control-Allow-Origin'] = "*"
    console.log("---")
    console.log(config)
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    if (response.config.responseType === 'blob') {
      return res
    }
    if (res.code !=400&&res.code != 0 && res.code != -2 && res.code != -5 && res.code != 200 && res.code != 222 && res.code != 100001) {
      if (res.code === 9999 || res.code === 401) {
        store.dispatch('user/resetLogin').then(() => {
          let href = window.location.href;
          if (href.indexOf("edu.bjxraq") !== -1) {
            //平台端0
            if (href.indexOf("safety_admin") >= 0) {
              location.href = '/safety_admin';
            } else {
              location.href = '/safety_business';
            }
          } else {
            location.href = process.env.NODE_ENV === "development" ? '/index' : '/safety_admin';
          }
        })

        return Promise.reject()
      }
      if (res.code == -3) {
        MessageBox.alert(res.msg, '提示信息')
        return Promise.reject()
      }
      // if (res.code == 725) {
      //   MessageBox.alert(res.message, '提示信息')
      //   return Promise.reject()
      // }

      Message.closeAll()
      // Message({
      //   message: res.msg || res.message || 'Error',
      //   type: 'error',
      //   duration: 1000
      // })
      return Promise.reject(res)
    }

    return res
  },
  error => {
    console.log(error) // for debug
    // if (error.name == "SyntaxError") {
    //   return error
    // }
    Message({
      message: error.msg,
      type: 'error',
      duration: 10 * 1000
    })

    return Promise.reject(error)
  }
)

export default service
