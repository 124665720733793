<template>
  <div class="certificate">
    <homeheader style="width: 100%"></homeheader>

    <div class="certificatemain">
      <div class="toptitle">证书查询</div>
      <el-form
        class="fromview"
        label-position="left"
        :model="ruleForm"
        label-width="257px"
      >
        <el-form-item class="itemview" prop="name">
          <label class="itemviewtitle" slot="label"
            >姓名<span style="color: red">*</span></label
          >
          <el-input v-model="ruleForm.name" placeholder="请输入真实姓名"></el-input>
        </el-form-item>
        <el-form-item class="itemview" prop="name">
          <label class="itemviewtitle" slot="label"
            >身份证号<span style="color: red">*</span></label
          >
          <el-input v-model="ruleForm.name" placeholder="请输入身份证号"></el-input>
        </el-form-item>

        <el-form-item class="itemview" prop="">
          <label class="itemviewtitle" slot="label">证书分类</label>
          <el-select
            style="width: 100%"
            v-model="ruleForm.region"
            placeholder="请选择证书分类"
          >
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="itemview" prop="">
          <label class="itemviewtitle" slot="label">证书编号</label>
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入证书编号，如：1234"
          ></el-input>
        </el-form-item>
        <el-form-item class="itemview" prop="name">
          <label class="itemviewtitle" slot="label"
            >验证码<span style="color: red">*</span></label
          >
          <el-input
            style="margin-right: 1rem"
            v-model="ruleForm.name"
            placeholder="请输入验证码"
          ></el-input>

          <canvas id="canvas" width="80px" height="30px" @click="handleClick"></canvas>
        </el-form-item>
      </el-form>
      <div style="margin: auto; justify-content: center; display: flex">
        <!-- <el-button @click="btnclick" class="edit" type="success">查询</el-button> -->
        <button @click="open" class="btn">查询</button>
      </div>
    </div>

    <homefooter> </homefooter>
  </div>
</template>

<script>
import homeheader from "./homeheader.vue";
import homefooter from "./homefooter.vue";
import ImageVerify from "./image-verify.js";
export default {
  name: "certificate",
  props: {
    msg: String,
  },
  components: {
    homeheader: () => import("./homeheader.vue"),
    homefooter,
  },
  mounted() {
    this.imageVerify = new ImageVerify({
      id: "canvas",
      width: 80,
      height: 30,
    });
  },
  data() {
    return {
      imageVerify: "",
      ruleForm: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: "blur",
          },
          {
            min: 3,
            max: 5,
            message: "长度在 3 到 5 个字符",
            trigger: "blur",
          },
        ],
        region: [
          {
            required: true,
            message: "请选择活动区域",
            trigger: "change",
          },
        ],
        date1: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        date2: [
          {
            type: "date",
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        type: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动性质",
            trigger: "change",
          },
        ],
        resource: [
          {
            required: true,
            message: "请选择活动资源",
            trigger: "change",
          },
        ],
        desc: [
          {
            required: true,
            message: "请填写活动形式",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    btnclick() {
      this.$router.push({
        name: "证书列表",
        params: {},
      });
    },
    open() {
      this.$confirm("无此证书", {
        showCancelButton: false,
        showConfirmButton: false,
        type: "warning",
        customClass: "msgbox",
      });
    },
    handleClick() {
      this.imageVerify.start();
      console.log(this.imageVerify.code);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-form-item__content {
  display: flex;
  border-left: #397eac solid 1px;
}

::v-deep.msgbox {
  width: 17.1875rem !important;
}

// ::v-deep .el-message-box {
// 	width: 17.1875rem;
// }

::v-deep .el-input__inner {
  border: 0;
}

.certificate {
  // max-width: 101.25rem;
  // margin-right: auto;
  // margin-left: auto;

  .certificatemain {
    margin-top: 2.75rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 74.625rem;
    height: 61rem;
    padding: 2rem;
    background: #f1f5fb;
    margin-bottom: 3.1875rem;

    .fromview {
      margin-top: 4.8125rem;
      margin-left: 10.625rem;
      margin-right: 10.625rem;

      .itemview {
        border: #397eac solid 1px;

        .itemviewtitle {
          margin-left: 1.875rem;
        }
      }
    }

    .toptitle {
      font-size: 1.75rem;
      font-weight: 400;
      color: #000;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
    }

    .btn {
      margin-top: 4.1875rem;
      padding: 1rem 5rem;
      background-color: #1d53bf;
      color: #fff;
      border-color: transparent;
      border: 0;
      outline: none;
      border-radius: 1rem;
    }
  }
}
</style>
