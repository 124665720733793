<template>
	<div class="boomcontainer">
		<div class="footer_top">
			<div class="top_con">
				<div class="top_one">
					<img src="../assets/footer_one.png" alt="">
					<span>主办单位</span>

				</div>
				<div class="top_two">
					北京晓瑞安全科技有限公司
				</div>

			</div>
			<div class="top_con">
				<div class="top_one">
					<img src="../assets/footer_two.png" alt="">
					<span>单位地址</span>
					<span style="display: inline-block;margin-left: 70px;" @click="open">管家</span>
				</div>
				<div class="top_two">
					北京市西城区茶马街8号院2号楼10层1011
				</div>

			</div>
			<div class="top_con">
				<div class="top_one">
					<img src="../assets/footer_three.png" alt="">
					<span>联系邮箱</span>
				</div>
				<div class="top_two">
					bjxraq@163.com
				</div>

			</div>
			<div class="top_con">
				<div class="top_one">
					<img src="../assets/footer_four.png" alt="">
					<span>联系电话</span>

				</div>
				<div class="top_two">
					010-88628863
				</div>

			</div>
		</div>
	<!-- 	<div class="one">
			<div class="login" @click.stop="open">
				<img src="../assets/head_anmin.png" alt="" />
				<span>平台管家</span>
				<span></span>
			</div>
			<div v-show="quickshow" id="login_containers" style="text-align: center"></div>
		</div> -->
		<div class="footerzhemu" @click.stop="open">
			<p>版权所有：晓瑞安全科技有限公司 京ICP备05046837号-1 京公网安备1101020200001 </p>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'homefooter',
		props: {
			msg: String
		},
		data() {
			return {}
		},
		methods: {
			open() {
				this.openUrl = "https://www.miit.gov.cn/";
				window.open(this.openUrl);
			},
		}
	}
</script>

<style scoped lang="scss">
	.boomcontainer {
		padding: 31px 0 0 0;
		box-sizing: border-box;
		display: flex;
		// position: relative;
		// height: 16rem;
		// max-width: 101.25rem;
		align-items: center;
		flex-wrap: wrap;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		background-color: #266FE8;
		color: #fff;
		flex-direction: column;

		.footer_top {
			display: flex;
			padding-bottom: 49px;

			.top_con {
				display: flex;
				flex-flow: column;
				margin-right: 20px;

				.top_one {
					img {
						width: 16.06px;
						height: 15.16px;

					}

					span {
						font-size: 14px;
						font-family: PingFang SC-Bold, PingFang SC;
						font-weight: bold;
						color: #FFFFFF;
						margin-left: 8px;


					}
				}

				.top_two {
					margin-top: 15px;
					font-size: 12px;
					font-family: PingFang SC-Regular, PingFang SC;
					font-weight: 400;
					color: #FFFFFF;

				}

			}
		}

		.footerzhemu {
			width: 100%;
			background: #1D53BF;
			border-radius: 0px 0px 0px 0px;
			opacity: 1;
			text-align: center;
			font-size: 12px;
			font-family: PingFang SC-Regular, PingFang SC;
			font-weight: 400;
			color: #FFFFFF;

		}


	}

	.one {
		position: relative;
		margin-right: 0.625rem;
		left: 600px;
		bottom: 20px;

		.login {
			//   width: 8rem /* 120/16 */;
			padding: 0px 14px;
			height: 2rem;
			line-height: 2rem;
			color: #fff;
			// background: #ffffff;
			border-radius: 0.1875rem;
			opacity: 1;
			display: flex;
			// align-items: center;
			justify-content: space-around;
			cursor: pointer;

			// img {
			// 	width: 12.52px;
			// 	margin-top: 5px;
			// 	height: 13px;
			// 	margin-right: 6px;
			// }

			span {
				font-size: 0.775rem;
				font-family: PingFang SC-Regular, PingFang SC;
				font-weight: 400;
				color: #191919;
			}
		}

		.logins {
			position: absolute;
			// bottom: -20px;
			top: 2.5rem;
			width: 7.5rem;
			height: 5.25rem;
			background: #ffffff;
			box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
			border-radius: 3px 3px 3px 3px;
			opacity: 1;
			padding-top: 0.75rem;
			box-sizing: border-box;

			.items {
				height: 45%;
				padding-left: 1.6875rem;
				box-sizing: border-box;
				cursor: pointer;
				font-size: 0.875rem;

				&:hover {
					font-size: 0.875rem;
					font-family: PingFang SC-Bold, PingFang SC;
					font-weight: bold;
					color: #266fe8;
					background: rgba(38, 111, 232, 0.1);
					border-left: 3px solid #266fe8;
				}
			}
		}
	}
</style>