<template>
	<div class="consultmain">
		<el-popover placement="right-end" width="160" v-model="visible" v-if="false" >
			<div class="item" slot="reference" style="cursor: pointer; line-height: auto">
				<img src="../assets/qrcode.png" alt="" style="width: 90%; height: 70%" />

				<span style="cursor: pointer; line-height: 10px">公众号</span>
			</div>
		</el-popover>
		<el-popover placement="right-end" width="160" v-model="visible">
			<p>微信咨询</p>
			<div style="text-align: right; margin: 0">
				<img src="../assets/back.jpg" style="width: 100%" alt="" />
			</div>

			<div class="item" slot="reference" style="cursor: pointer">
				<img v-if="!zixun" src="../assets/zixun1.png" alt="" style="width: 1.825rem; height: 2.125rem"
					@mouseover="imgmous(1)" />
				<img v-else src="../assets/zixun2.png" alt="" style="width: 1.825rem; height: 2.125rem"
					@mouseout="imgmous(5)" />
				<span>在线咨询</span>
			</div>
		</el-popover>
		<el-popover placement="right-end" width="260" v-model="visible1">
			<p style=" margin: 0;text-align: center;">咨询电话</p>
			<div style=" margin: 0;text-align: center;margin-top: 10px;">
				010-88628863
			</div>
			<div class="item" slot="reference" style="cursor: pointer">
				<img v-if="!dianhua" src="../assets/dianhua1.png" alt="" style="width: 1.825rem; height: 2.125rem"
					@mouseover="imgmous(2)" />
				<img v-else src="../assets/dianhua2.png" alt="" style="width: 1.825rem; height: 2.125rem"
					@mouseout="imgmous(5)" />
				<span>电话咨询</span>
			</div>
		</el-popover>
		<el-popover placement="right-end" width="160" v-model="visible2">
			<p>微信咨询</p>
			<div style="text-align: right;">
				<img src="../assets/lxwx.png" style="width: 100%;margin-top:10px;" alt="" />
			</div>

			<div class="item" slot="reference" style="cursor: pointer">
				<img v-if="!vx" src="../assets/vx1.png" alt="" style="width: 1.825rem; height: 2.125rem"
					@mouseover="imgmous(3)" />
				<img v-else src="../assets/vx2.png" alt="" style="width: 1.825rem; height: 2.125rem"
					@mouseout="imgmous(5)" />
				<span>微信沟通</span>
			</div>
		</el-popover>
		<!-- <el-popover placement="right-end" width="160" v-model="visible">
      <div class="item" slot="reference" @click="clicknav()" style="cursor: pointer">
        <img
          v-if="!zhengshu"
          src="../assets/zhengshu1.png"
          alt=""
          style="width: 1.825rem; height: 2.125rem"
          @mouseover="imgmous(4)"
        />
        <img
          v-else
          src="../assets/zhengshu2.png"
          alt=""
          style="width: 1.825rem; height: 2.125rem"
          @mouseout="imgmous(5)"
        />
        <span>证书查询</span>
      </div>
    </el-popover> -->
	</div>
</template>

<script>
	export default {
		name: "consult",
		props: {
			msg: String,
		},
		data() {
			return {
				visible: false,
				visible1: false,
				visible2: false,
				data: [],
				zixun: false,
				dianhua: false,
				vx: false,
				zhengshu: false,
			};
		},
		methods: {
			imgmous(type) {
				this.visible = false
				this.visible1 = false
				this.visible2 = false
				switch (type) {
					case 1:
						this.zixun = true;
						this.dianhua = false;
						this.vx = false;
						this.zhengshu = false;

						break;
					case 2:
						this.zixun = false;
						this.dianhua = true;
						this.visible1 = true
						this.vx = false;
						this.zhengshu = false;

						break;
					case 3:
						this.zixun = false;
						this.dianhua = false;
						this.vx = true;
						this.visible2 = true;
						this.zhengshu = false;

						break;
					case 4:
						this.zixun = false;
						this.dianhua = false;
						this.vx = false;
						this.zhengshu = true;

						break;
					case 5:
						this.zixun = false;
						this.dianhua = false;
						this.vx = false;
						this.zhengshu = false;

						break;

					default:
						break;
				}
			},
			itemclick(e) {
				this.visible = true;
				if (e == 1) {}
				if (e == 2) {}
				if (e == 3) {}
			},
			// clicknav() {
			//   this.$router.push({
			//     name: "证书",
			//   });
			// },
		},
	};
</script>

<style scoped lang="scss">
	span {
		font-size: 16px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
	}

	.consultmain {
		z-index: 9999;

		.item {
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			border-bottom: #777 solid 1px;
			line-height: 2rem;
			margin-bottom: 1.25rem;
			background: #ffffff;
			box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1608);
			border-radius: 10px 10px 10px 10px;

			width: 4.75rem;
			height: 5.25rem;

			span {
				font-size: 12px;
				font-family: PingFang SC-Regular, PingFang SC;
				font-weight: 400;
				color: #000000;
			}

			// margin-right:22.375rem;
		}
	}
</style>