const getters = {
  sidebar: state => state.app.sidebar,
  rightsidebar: state => state.app.rightsidebar,
  language: state => state.app.language,
  size: state => state.app.size,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  introduction: state => state.user.introduction,
  id: state => state.user.id,
  //1.平台端,2.企业端
  loginPlatform: state => state.user.loginPlatform,
  companyId: state => state.user.companyId,
  roles: state => state.user.roles,
  bindWeiXin: state => state.user.bindWeiXin,
  permission_routes: state => state.permission.routes,
  errorLogs: state => state.errorLog.logs,
  ROLE: state => state.user.role,
  permission: state => state.user.permission,
}
export default getters
