import request from '@/utils/request'

export function indexlayout() {
    return request({
        url: '/api/index_layout',
        get: 'get',
    })
}
//成功案例
export function case_info(data) {
    return request({
        url: '/api/case_info',
        method: 'post',
        data
    })
}
//问与答
export function question(data) {
    return request({
        url: '/api/question',
        method: 'post',
		data
    })
}

export function newfileUpload(data, callback) {
    return request({
        url: '/base/upload_image',
        method: 'post',
        headers: { "Content-Type": "multipart/form-data" },
        data,
    })
}
//反馈类型接口
export function getverification() {
    return request({
        url: '/api/feedback_class',
        method: 'get',
    })
}
//提交反馈
export function submitfeedback(data) {
    return request({
        url: '/api/feedback',
        method: 'post',
        data
    })
}
//
export function getarticle(data) {
    return request({
        url: '/api/article',
        method: 'post',
         data
    })
}
// 相关链接
export function aboutlink() {
    return request({
        url: '/api/link',
        method: 'get',
    })
}
export function index_click() {
    return request({
        url: '/api/index_click',
        method: 'get',
    })
}

// 相关链接列表
export function linkChild(data) {
    return request({
        url: '/api/linkChild',
        method: 'post',
		data
    })
}
//新闻数据
export function news_info(data) {
    return request({
        url: '/api/news_info',
        method: 'post',
        data
    })
}
// https://edu.bjxraq.com/security/api/website/redirect/login/1
export function news_detail(data) {
    return request({
        url: '/api/news_detail ',
		method: 'post',
		data
    })
}