<template>
  <div class="homepage">
    <homeheader :list="navtitle" style="width: 100%"></homeheader>
    <div style="" class="homemain">
      <div class="hometop">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              @click="bannerclick(item)"
              class="swiper-slide"
              v-for="(item, index) in topbanner"
              :key="index"
            >
              <!-- <a href=""> -->
              <img
                style="
                  vertical-align: middle;
                  width: 100%;
                  height: 100%;
                  overflow: hidden;
                "
                :src="item.img[0]"
                alt
              />
              <!-- </a> -->
              <div class="zhezhao">
                <p>{{ item.title }}</p>
              </div>
            </div>
          </div>
          <!-- 如果需要分页器 -->
          <div class="swiper-pagination" slot="pagination"></div>
        </div>
        <div class="news">
          <ul class="newsnav">
            <li
              :class="['show', index == slectedNew ? 'active' : ' ']"
              style="margin-right: 5rem; cursor: pointer"
              v-for="(item, index) in newstitle"
              :key="index"
              @click="newnavclick(item, index)"
            >
              {{ item.title }}
            </li>
          </ul>
          <!-- <div class="line"></div> -->
          <div class="mewslist" style="max-height: 20.75rem; overflow-y: auto">
            <ul style="padding: 0; padding-left: 20px">
              <li
                class="newitem"
                v-for="(items, index) in listnews"
                v-if="index >= 6 ? false : true"
                :key="index"
              >
                <div
                  @click="iconclick(items)"
                  style="
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                  "
                >
                  <div class="listTitle">
                    {{ items.title }}
                  </div>
                  <div
                    style="
                      margin-right: 15px;
                      color: #888;
                      width: 150px;
                      display: inline-block;
                      text-align: right;
                    "
                  >
                    {{ items.update_time2 }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div @click="moreclick" class="more">更多>></div>
        </div>
      </div>
      <!-- //图标列表 -->
      <div :class="['listicons']">
        <div
          @click="iconclick(item)"
          class="iconitem"
          v-for="(item, indexs) in imgs"
          :key="indexs"
          @mousemove="imagemou(indexs)"
          @mouseout="imagemous"
        >
          <img
            :src="slectedicon == indexs ? item.img[1] : item.img[0]"
            alt=""
          />
          <p>{{ item.title }}</p>
          <div v-html="item.profile"></div>
          <!-- <div>{{ item.text2 }}</div> -->
        </div>
      </div>
      <!-- //轮播推广/// :style="{backgroundImage: 'url(' +item.back+ ')'}" -->
      <div class="scrolllist">
        <div
          class="scrolllistitem"
          v-for="(item, index) in scrolllist"
          :key="index"
        >
          <!-- //上 -->
          <div class="top_one">
            <img :src="item.img" alt="" />
            <span>{{ item.title }}</span>
          </div>
          <!-- 内容 -->
          <div @click="iconclick(item)" class="content">
            <p>
              {{ item.title }}
            </p>
          </div>
        </div>
      </div>
      <!-- 交流中心 -->
      <div class="exchangecomtent">
        <div
          class="exchangecomtent_item"
          v-for="(item, index) in fenlei"
          :key="index"
        >
          <div class="exchangecomtent_itemtop">
            <img
              style="
                mask-repeat: no-repeat;
                height: 100%;
                display: inline-block;
              "
              :src="item.img[0]"
              alt=""
            />
            <p>{{ item.title }}</p>
          </div>

          <div class="exchangecomtentmewslist">
            <ul>
              <li
                @click="iconclick(items)"
                class="exchangecomtentnewitem"
                v-for="(items, index) in item.child"
                :key="index"
              >
                <div
                  style="
                    margin-bottom: 0.625rem;
                    list-style: none;
                    display: flex;
                    justify-content: space-between;
                  "
                >
                  <!-- <a href=""> -->
                  <span>{{ items.title }} </span>
                  <!-- <span style="margin-right: 1rem">{{items.update_time}}</span> -->
                  <!-- </a> -->
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- //底部图片列表 -->
      <div class="homeInterface">
        <div class="homeInterfaceitem">
          <!-- loopList -->
          <div class="boomswiper-container">
            <div class="swiper-wrapper">
              <div
                @click="bannerclick(item)"
                class="swiper-slide"
                v-for="(items, index) in bottombanner[0].child"
                :key="index"
              >
                <!-- <a href="" class="imga"> -->
                <img
                  style="width: 100%; height: 100%; overflow: hidden"
                  :src="items.img[0]"
                  alt
                />
                <!-- </a> -->
                <div class="boomzhezhao">
                  <p>{{ items.title }}</p>
                </div>
              </div>
            </div>
            <!-- 如果需要分页器 -->
            <div class="swiper-pagination" slot="pagination"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- //底部图标列表 -->
    <div class="boomlisticons">
      <div
        class="iconitem"
        v-for="(item, index) in boomicon"
        :key="index"
        @click="boomiconclick(item)"
      >
        <img :src="item.img[0]" alt="" />
        <p>{{ item.title }}</p>
      </div>
    </div>

    <consult class="consultview"></consult>
    <homefooter style="width: 100%"></homefooter>
  </div>
</template>

<script>
import { MessageBox, Message } from "element-ui";
import img1 from "@/assets/nature-1.jpg";
import img2 from "@/assets/nature-2.jpg";
import img3 from "@/assets/nature-3.jpg";

import img01 from "@/assets/falv1.png";
import img02 from "@/assets/biaozhun1.png";
import img03 from "@/assets/shigu1.png";
import img04 from "@/assets/shuju1.png";
import img05 from "@/assets/kaoshi1.png";
import img06 from "@/assets/jiaoyu1.png";
import img07 from "@/assets/tiaosao1.png";
import img08 from "@/assets/falv2.png";
import img09 from "@/assets/biaozhun2.png";
import img10 from "@/assets/shigu2.png";
import img11 from "@/assets/shuju2.png";
import img12 from "@/assets/kaoshi2.png";
import img13 from "@/assets/jiaoyu2.png";
import img14 from "@/assets/tiaosao2.png";
import img15 from "@/assets/hegui1.png";
import img16 from "@/assets/hegui2.png";
import img17 from "@/assets/yinhuan1.png";
import img18 from "@/assets/yinhuan2.png";

import diyi from "@/assets/diyi.png";
import gongyi from "@/assets/gongyi.png";
import tashan from "@/assets/tashan.png";

import yijian from "@/assets/yijian.png";
import wenti from "@/assets/wenti.png";
import lianjie from "@/assets/lianjie.png";
import ditu from "@/assets/ditu.png";
import mianze from "@/assets/mianze.png";

import homeheader from "./homeheader.vue";
import homefooter from "./homefooter.vue";
import consult from "./consult.vue";

import Swiper from "swiper";
import "swiper/css/swiper.min.css"; // 注意这里的引入
import { redict } from "@/api/home";
import {
  indexlayout,
  news_info,
  news_detail,
  getarticle,
  index_click,
} from "@/api/home";

import swiper from "swiper";
import { triggerRef } from "vue";
export default {
  name: "homepage",
  props: {
    msg: String,
  },
  components: {
    homeheader,
    homefooter,
    Swiper,
    consult,
  },
  mounted() {
    var myswiper = new Swiper(".swiper-container", {
      // config...
      effect: "fade",
      touchRatio: 0.1,
      fade: {
        crossFade: true,
      },
      autoplay: true,
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, //修改swiper的父元素时，自动初始化swiper
      pagination: {
        el: ".swiper-pagination",
        paginationClickable: true,
        clickable: true, // 允许点击小圆点跳转
      },
      onlyExternal: true,
      followFinger: false,
    });
    var myswiper = new Swiper(".boomswiper-container", {
      // config...
      effect: "fade",
      touchRatio: 0.1,
      autoplay: true,

      fade: {
        crossFade: true,
      },
      observer: true,
      onserveParents: true,
      pagination: {},
      onlyExternal: true,
      followFinger: false,
    });
  },
  created() {
    this.getdata();
    this.getlist();
  },
  methods: {
    descclick(item) {
      this.$router.push({
        name: "新闻详情",
        query: {
          id: item.id,
        },
      });
    },
    getlist() {
      index_click().then((res) => {
        console.log(res);
      });
    },
    getdata() {
      indexlayout().then((res) => {
        console.log(res);
        // this.navtitle = res.data[0].child;
        this.imgs = res.data[1].child;
        for (var index in this.imgs) {
          this.imgs[index].show = false;
        }
        console.log("this.imgs========", this.imgs);
        this.scrolllist = res.data[2].child;
        this.fenlei = res.data[3].child;
        this.boomicon = res.data[4].child;
        this.bottombanner = res.data[6].child;
        this.topbanner = res.data[5].child;
        this.newstitle = res.data[7].child;

        this.listnews = this.newstitle[0].child;

        this.$forceUpdate();
      });
    },
    moreclick() {
      this.$router.push({
        name: "新闻",
      });
    },
    imagemou(index) {
      // for (let i in this.imgs) {
      //   this.imgs[i].show = false;
      // }

      // this.imgs[idx].show = true;
      this.slectedicon = index;
      //  console.log("imgs======", this.imgs);

      //   console.log("img======", this.imgs[idx]);

      // console.log("index======", index);
    },
    imagemous() {
      // for (let i in this.imgs) {
      //   this.imgs[i].show = false;
      // }
      this.slectedicon = null;
    },
    newnavclick(e, index) {
      this.slectedNew = index;
      console.log("点击了============", this.slectedNew);
      this.listnews = e.child;
    },
    bannerclick(item) {
      // console.log("e==========", e);
      getarticle({
        id: item.id,
      }).then((res) => {
        if (res.code == 400) {
          this.$message({
            message: res.message,
            type: "warning",
          });
        } else {
          // 0.暂未开放 1.列表 2.内容 3.外链 4.内链
          if (res.data.type == 2) {
            this.$router.push({
              name: "详情",
              query: {
                id: item.id,
              },
            });
          }
          if (res.data.type == 3) {
            window.open(res.data.link);
          }
        }
      });
    },
    iconclick(item) {
      // console.log("e==========", e);
      getarticle({
        id: item.article_id,
      }).then((res) => {
        console.log("code=============");

        if (res.code == 400) {
          console.log("code=====1111111========");
          Message({
            message: res.message,
            type: "error",
            duration: 1000,
          });
        } else {
          if (res.data.type == 2) {
            this.$router.push({
              name: "详情",
              query: {
                id: item.article_id,
              },
            });
          }
          if (res.data.type == 3) {
            window.open(res.data.link);
          }
        }
        // 0.暂未开放 1.列表 2.内容 3.外链 4.内链
      });
    },
    boomiconclick(item) {
      if (item.title == "意见反馈") {
        {
          this.$router.push({
            name: "意见反馈",
          });
        }
      } else if (item.title == "相关链接") {
        {
          this.$router.push({
            name: "相关链接",
          });
        }
      } else {
        this.iconclick(item);
      }
    },
  },
  data() {
    return {
      page: {
        offset: 10,
        page: 0,
        total: 0,
      },
      titlearry: [],
      listnews: [],
      slectedicon: null,
      navtitle: [],
      topbanner: [],
      bottombanner: [1, 2, 3, 4],
      scrolllist: [],
      boomicon: [
        {
          img: yijian,
          name: "意见反馈",
        },
        {
          img: wenti,
          name: "常见问题",
        },
        {
          img: lianjie,
          name: "相关链接",
        },
        {
          img: ditu,
          name: "网站地图",
        },
        {
          img: mianze,
          name: "免责声明",
        },
      ],
      fenlei: [
        {
          img: diyi,
          name: "第一资讯",
        },
        {
          img: gongyi,
          name: "公益讲堂",
        },
        {
          img: tashan,
          name: "他山之石",
        },
      ],
      loopList: [img1, img2, img3],
      imgs: [
        {
          img: img01,
          imgs: img08,
          show: false,
          name: "法律法规",
          // text1: "10万+数据",
          // text2: "2千+企业使用",
        },
        {
          img: img02,
          imgs: img09,
          show: false,
          name: "标准规范",
          // text1: "10万+数据",
          // text2: "2千+企业使用",
        },
        {
          img: img03,
          imgs: img10,
          show: false,
          name: "事故案例",
          // text1: "10万+数据",
          // text2: "2千+企业使用",
        },
        {
          img: img04,
          imgs: img11,
          show: false,
          name: "数据资源",
          // text1: "10万+数据",
          // text2: "2千+企业使用",
        },
        {
          img: img05,
          imgs: img12,
          show: false,
          name: "在线考试",
          // text1: "10万+数据",
          // text2: "2千+企业使用",
        },
        {
          img: img06,
          imgs: img13,
          show: false,
          name: "教育培训",
          // text1: "10万+数据",
          // text2: "2千+企业使用",
        },
        {
          img: img07,
          imgs: img14,
          show: false,
          name: "跳蚤市场",
          // text1: "10万+数据",
          // text2: "2千+企业使用",
        },
        {
          img: img15,
          imgs: img16,
          show: false,
          name: "合规性审查 ",
          // text1: "10万+数据",
          // text2: "2千+企业使用",
        },
        {
          img: img17,
          imgs: img18,
          show: false,
          name: "隐患排查 ",
          // text1: "10万+数据",
          // text2: "2千+企业使用",
        },
      ],

      slectedNew: 0,
      newstitle: [
        {
          name: "行业要闻",
        },
        {
          name: "协会新闻",
        },
        {
          name: "党群动态",
        },
        {
          name: "通知公共",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
}

::v-deep .swiper-container .swiper-pagination-bullet {
  width: 25px;
  height: 3px;
  display: inline-block;
  border-radius: 0;
  background: #000;
  opacity: 0.2;
}

::v-deep .swiper-container .swiper-pagination-bullet-active {
  opacity: 1;
  background-color: #fff !important;
}

span {
  color: #000;
}

.homepage {
  // padding-right: var(--bs-gutter-x, 0.75rem);
  // padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
  //  background: #F6F7F9;
  background: #f6f7f9;

  .homemain {
    max-width: 101.25rem;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
  }

  .consultview {
    position: fixed;
    top: 40%;
    right: 0.375rem;
  }

  .hometop {
    width: 101.25rem;
    height: 29.75rem;
    background: #ffffff;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    display: flex;
    background: #fff;
  }

  // flex-wrap: wr;
  .swiper-container {
    margin-left: 0;
    margin-right: 20px;
    width: 50%;
    // height: 20rem;
    margin: 1rem;

    .swiper-slide {
      width: 100%;
    }

    .zhezhao p {
      padding-top: 1.3rem;
      color: #fff;
      text-align: center;
      margin-top: 10px;
      padding-left: 1vw;
    }

    .zhezhao {
      width: 100%;
      height: 5.3125rem;
      // background-color: #000;
      position: absolute;
      bottom: 0;

      background-color: rgba(1, 1, 1, 0.4);
    }
  }

  .boomswiper-container {
    position: relative;
    margin-left: 0;
    margin-right: 20px;
    width: 100%;
    height: 15rem;
    height: 100%;

    .swiper-wrapper {
      height: 100%;

      .swiper-slide {
        img {
          width: 100%;
          height: 100%;
        }

        .boomzhezhao {
          width: 100%;
          height: 2.25rem;
          background-color: #000;
          position: absolute;
          text-align: center;
          bottom: 0;
          opacity: 0.5;

          p {
            padding: 0;
            margin: 0;
            // position: absolute;
            line-height: 2.25rem;
            color: #fff;
            // text-align: left;
            padding-left: 1.625rem;
            font-size: 1.0625rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
          }
        }
      }
    }
  }

  .listicons {
    margin-top: 1.3125rem;
    display: flex;
    height: 20rem;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 1.3125rem;
    background-color: white;
    flex-wrap: wrap;

    justify-content: flex-start;
    overflow: hidden;
    padding: 0 0 0.625rem 0;

    // &:hover {
    //   height: auto;
    // }

    .iconitem {
      min-width: 14%;
      display: flex;
      flex-direction: column;
      //   height: 10.6875rem;
      margin-top: 1.6875rem;
      // justify-content: center;
      color: #000;
      cursor: pointer;

      img {
        width: 4.8125rem;
        height: 4.8125rem;
        margin-left: auto;
        margin-right: auto;
      }

      p:first-of-type {
        text-align: center;
        font-size: 20px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #000000;
        margin: 0.625rem 0;
      }

      div {
        text-align: center;
        margin: 0;
        margin: 0.625rem 0;

        &:first-of-type {
          margin: 0 0 0 0;
        }
      }
    }
  }

  //   .listiconss {
  //     height: auto;
  //   }

  .news {
    width: 50%;
    background: #fff;
    position: relative;

    .more {
      cursor: pointer;
      position: absolute;
      bottom: 20px;
      right: 18px;
      border-bottom: 1px solid #000000;
      font-size: 1.25rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.902);
      line-height: 22px;
    }

    .newsnav {
      list-style: none;
      display: flex;
      padding: 0px;
      overflow-x: auto;
      background: #fff;
      font-size: 1.0625rem;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.902);
      border-bottom: 2px solid rgba(38, 111, 232, 0.3);
      margin-right: 1.0625rem;
    }

    .newsnav li {
      float: left;
      line-height: 1.375rem;
      color: black;
      min-width: 1rem;
      padding: 0.5rem 1rem;
    }

    .show {
      color: black;
      background: #fff;
    }

    .active {
      color: #fff !important;
      background: #266fe8;
    }

    .newsnav a {
      font-size: 1.25rem;
      text-decoration: none;
      text-decoration: none;
    }

    .line {
      background: #266fe8;
      height: 1px;
    }

    .newitem {
      margin-top: 1.25rem;
      color: #191919;

      span {
        font-size: 1.0625rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;

        &:hover {
          color: rgba(38, 111, 232, 1);
        }
      }

      &:hover {
        color: rgba(38, 111, 232, 1);
      }
    }
  }

  .homeInterface {
    // display: flex;
    margin-top: 1.3125rem;
    position: relative;
    overflow: hidden;
    margin-bottom: 1.3125rem;

    .homeInterfaceitem {
      // width: 24.375rem;
      height: 15.4375rem;
      margin-right: 3%;
      overflow: hidden;

      .pose {
        position: absolute;
        bottom: 0.2rem;
        height: 50px;
        line-height: 50px;
        background: rgba(0, 0, 0, 0.3);
        width: 22%;
        color: #fff;
        font-size: 16px;
      }
    }

    .homeInterfaceitem:last-child {
      margin-right: 0;
    }
  }

  .scrolllist {
    display: flex;

    .scrolllistitem {
      width: 33%;
      box-sizing: border-box;
      margin-right: 1.25rem;
      // display: flex;
      position: relative;
      background: #fff;

      &:last-child {
        margin-right: 0;
      }

      .top_one {
        padding-left: 40px;
        // width: 100%;
        height: 5rem;
        background: url("../assets/backchanpin.png");
        display: flex;
        align-items: center;
        background-size: 100%;

        img {
          width: 43px;
          height: 43px;
        }

        span {
          margin-left: 20px;
        }
      }

      .content {
        padding: 30px 30px 40px;
        box-sizing: border-box;
        cursor: pointer;
        color: #191919;

        &:hover {
          color: rgba(38, 111, 232, 1);
        }

        p {
          margin: 0;
          padding: 0;
          font-size: 0.875rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
        }
      }

      .top_two {
        background: url("../assets/backjishu.png");

        background-size: 100%;
      }

      .top_three {
        background: url("../assets/backzixun.png");

        background-size: 100%;
      }
    }
  }

  .exchangecomtent {
    display: flex;
    margin-top: 1.1875rem;
    max-height: 35.0625rem;
    overflow: hidden;

    div:last-child {
      margin-right: 0;
    }

    .exchangecomtent_item {
      background: #fff;
      width: 33%;
      overflow: hidden;
      box-sizing: border-box;
      // margin: .5rem;
      margin-right: 1.25rem;

      .exchangecomtent_itemtop {
        width: 100%;
        // height: 100%;
        position: relative;
        overflow: hidden;
        // height: 10.375rem;
        height: 5.625rem;
        margin-bottom: 0.625rem;

        p {
          position: absolute;
          margin: 0%;
          display: inline;
          z-index: 9;
          right: 3.125rem;
          top: 0;
          font-size: 40px;
          line-height: 5.625rem;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #ffffff;
        }

        img {
          width: 100%;
          height: 5.625rem;
        }
      }

      .exchangecomtentmewslist {
        padding-bottom: 1rem;

        .exchangecomtentnewitem {
          margin-top: -0.5rem;
          padding-top: 0.5rem !important;
          padding-bottom: 0.5rem !important;

          &:hover {
            color: rgba(38, 111, 232, 1);
          }

          span {
            font-size: 1.0625rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #191919;
            cursor: pointer;

            &:hover {
              color: rgba(38, 111, 232, 1);
            }
          }
        }
      }
    }
  }

  .boomlisticons {
    display: flex;
    max-width: 101.25rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: space-around;
    height: 9.6875rem;
    margin-bottom: 1.3125rem;
    background-color: white;
    align-items: center;

    .iconitem {
      width: 18.75rem;
      display: flex;
      height: 5.5rem;

      color: #000;
      background: #f8f8f8;
      align-items: center;
      cursor: pointer;

      &:hover {
        transform: scale(1.2);
      }

      img {
        width: 3.125rem;
        height: 3.125rem;
        margin-left: 1.6875rem;
      }

      p {
        margin-left: 2.125rem;
        text-align: center;
        font-size: 20px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #000000;
      }
    }
  }
}

.imga > img:hover {
  transform: scale(1.2);
}

.listTitle {
  font-size: 28rpx;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #222426;
  line-height: 80rpx;
  // 从上到下垂直排列子元素（设置伸缩盒子的子元素排列方式）

  width: 400rpx;
  //超出两行省略号
  // max-width: 400rpx;
  // display: -webkit-box;
  // white-space: nowrap; // 强制一行
  overflow: hidden;
  // -webkit-box-orient: vertical;
  // line-clamp: 1;
  -webkit-line-clamp: 1; //显示几行

  white-space: nowrap; // 强制一行
  text-overflow: ellipsis; // 文字溢出显示省略号
}

.listTitle:hover {
  -webkit-line-clamp: 2; //显示几行
  white-space: wrap; // 强制一行
  // overflow: no;
}
</style>