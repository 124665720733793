<template>
	<div class="FitTable">
		<el-table :header-cell-style="{background:'#F1F1F1',color:'#2A2E35',fontsize:'14px'}" ref="multipleTable" v-loading="loading" :data="data" @selection-change="handleSelectionChange"
			@row-click="handleRowClick">
			<!-- 多选 -->
			<el-table-column v-if="option.selection || option.selection == null" type="selection" width="80">
			</el-table-column>
			<el-table-column v-if="option.radio" fixed="left" width="80">
				<template slot-scope="scope">
					<div class="radio-col">
						<el-radio v-model="option.radioval" class="radio" :label="scope.row">
							<i></i>
						</el-radio>
					</div>
				</template>
			</el-table-column>
			<!-- 序号 -->
			<el-table-column v-if="option.index || option.index == null" fixed="left" label="序号" type="index"
				width="80"></el-table-column>

			<template v-for="(item, index) in option.column">
				<el-table-column v-if="item.hide == true ? false : true" :label="item.name" :prop="item.prop"
					:width="item.width" :sortable="item.sort || false"
					:show-overflow-tooltip="item.overflow != null ? item.overflow : true">
					<template slot-scope="scope">
						<!-- text 普通or文本=>String -->
						<template v-if="item.type == null || item.type == 'text'">{{ scope.row[item.prop]
						}}</template>
						<!-- image 图片=>String -->
						<template v-if="item.type == 'image'">
							<el-image style="width: 90px; height: 60px" fit="contain" :src="scope.row[item.prop]"
								:preview-src-list="[scope.row[item.prop]]"></el-image>
						</template>
						<!-- select 选项=> dicData: {下拉数据: []} -->
						<template v-if="item.type == 'select'">
							<span v-for="(items, indexs) in item.dicData" :key="indexs"
								v-show="items.value == scope.row[item.prop]">{{ items.label }}</span>
						</template>
						<!-- switch 开关=>Boolean -->
						<template v-if="item.type == 'switch'">
							<el-switch v-model="scope.row[item.prop]" :active-value="0" :inactive-value="1"
								active-color="#518DF5" inactive-color="#8B8B8B"
								@change="handleSwitchChange($event, scope, item.prop)"></el-switch>
						</template>
						<!-- slot 插槽 -->
						<template v-if="item.type == 'slot'">
							<slot :name="item.prop" :row="scope.row" :$index="scope.$index"></slot>
						</template>
					</template>
				</el-table-column>
			</template>

			<!-- 操作 -->
			<el-table-column v-if="option.menu" fixed="right" label="操作">
				<template slot-scope="scope">
					<div class="menu_btns">
						<slot name="menu" :row="scope.row" :$index="scope.$index"></slot>
					</div>
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页器 -->
		<div class="pagination" v-if="pager">
			<el-pagination v-show="page.total > 0" background layout="prev, pager, next" :page-size="page.PageSize"
				:current-page.sync="page.PageIndex" :total="page.total" @current-change="handleCurrentChange">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'FitTable',
		props: {
			page: {
				type: Object, // 分页器配置
				default: null,
			},
			data: {
				type: Array, // 表格数据
				default: null,
			},
			option: {
				type: Object, // 表格配置项
				default: null,
			},
			loading: {
				type: Boolean, // loading
				default: false,
			},
			pager: {
				type: Boolean, // loading
				default: true,
			},
		},
		data() {
			return {
				radio: null,
			}
		},
		methods: {

			mainStyle(column) {
				return "color:white";
			},
			// 多选change
			handleSelectionChange(val) {
				// val 返回的选中项 [{key: value}...]
				this.$emit('selectionChange', val)
			},
			// 单选
			handleRowClick(val) {
				this.$emit('rowClick', val)
			},
			// 开关change
			handleSwitchChange(type, data, key) {
				let val = {
					type: type, // Boolean
					data: data.row, // 当前项数据
					index: data.$index, // 下标
					key,
				}
				this.$emit('switchChange', val)
			},
			// 页码change
			handleCurrentChange(val) {
				this.$emit('currentChange', val)
			},
			// 默认 多选项
			toggleSelection(ids) {
				let ins = []

				for (let i in ids) {
					for (let j in this.data) {
						if (ids[i] == this.data[j].id) {
							ins.push(this.data[j])
						}
					}
				}

				if (ins) {
					ins.forEach((row) => {
						this.$refs.multipleTable.toggleRowSelection(row, true) // 回显
					})
				} else {
					this.$refs.multipleTable.clearSelection()
				}
			},
		},

	}
</script>

<style scoped lang="scss">
	::v-deep .el-table__header col[name="gutter"] {
		display: table-cell !important;
	}

	::v-deep .el-table {
		.el-table__cell {}

		th.el-table__cell>.cell,
		.cell {
			padding-left: 22px !important;
		}
	}

	::v-deep .el-table td .cell,
	.el-table th .cell {
		// color: rgba(0, 0, 0, 0.9);
	}

	::v-deep .el-table::before {
		height: 0px;
	}

	::v-deep .el-table__header {
		height: 42px;
		background: #F1F1F1;
		border-radius: 5px;
		overflow: hidden;
	}

	::v-deep .el-table__row {
		height: 42px;
		box-sizing: border-box;
		background-color: #feffff;
	}

	::v-deep .el-checkbox__inner {
		width: 15px;
		height: 15px;
	}

	::v-deep .el-checkbox__inner::after {
		left: 50%;
		top: 50%;
		transform: translate(-50%, -70%) rotate(45deg) scale(1.5) !important;
	}

	::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%) !important;
	}

	::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
	::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
		background-color: #518DF5 !important;
		border-color: #518DF5 !important;
	}

	.pagination {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #FEFFFF;
		padding: 0px 10px 10px 10px;

	}

	::v-deep .menu_btns {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		>div {
			margin-right: 10px;
		}
	}

	.radio-col {
		display: flex;
		align-items: center;
		justify-content: center;

		::v-deep .el-radio__inner {
			width: 20px;
			height: 20px;
		}
	}
</style>
