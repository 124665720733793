import Cookies from 'js-cookie'

const TokenKey = 'token'
const UserId = 'User-Id'
const LoginPlatform = 'Login_Platform'
const CompanyId = 'Company_Id'

export function setCompanyId(companyId) {
  return Cookies.set(CompanyId, companyId)
}
export function getCompanyId() {
  return Cookies.get(CompanyId)
}

export function setLoginPlatform(loginPlatform) {
  return Cookies.set(LoginPlatform, loginPlatform)
}
export function getLoginPlatform() {
  return Cookies.get(LoginPlatform)
}

export function setUserId(id) {
  return Cookies.set(UserId, id)
}
export function getUserId() {
  return Cookies.get(UserId)
}

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
export function removeUserId() {
  return Cookies.remove(UserId)
}
export function removeLoginPlatform() {
  return Cookies.remove(LoginPlatform)
}
export function removeCompanyId() {
  return Cookies.remove(CompanyId)
}
