<template>
	<div class="certificatelist">

		<homeheader></homeheader>

		<div class="certificatelistmain">
			<FitTable ref="FitTable" :page="page" :data="tableData" :option="tableOption" :loading="loading"
				@currentChange="currentChange">
			</FitTable>
			<div class="certificatelistmainbtn">
				<button @click="btnclick" class="btn">重新查询</button>
			</div>

		</div>

		<homefooter></homefooter>

	</div>
</template>

<script>
	import homeheader from "./homeheader.vue";
	import homefooter from "./homefooter.vue";
	import FitTable from "./FitTable/index.vue";
	export default {
		name: "certificate",
		props: {
			msg: String,
		},
		components: {
			homeheader,
			homefooter,
			FitTable
		},
		data() {
			return {
				page: {
					PageIndex: 0,
					PageSize: 0,
					total: 0,
				},
				ruleForm: {},
				loading: false,
				tableData: [],
				rules: {},
				tableOption: {
					index: false,
					selection: false,
					menu: true,
					column: [{
							prop: 'metricsName',
							name: '姓名',
							type: 'text',
						},
						{
							prop: 'metricsDataType',
							name: '身份证号',
							type: 'text',
						},
						{
							prop: 'remark',
							name: '证书编号',
							type: 'text',
						}
					],
				},

			}
		},
		methods: {
			btnclick(){
				this.$router.go(-1)
			},
			currentChange() {}
		}
	}
</script>

<style scoped lang="scss">
	.certificatelist {
		max-width: 101.25rem;
		margin-right: auto;
		margin-left: auto;

		.certificatelistmain {
			// margin-top: 5rem;
			background: skyblue;
			max-width: 101.25rem;
			margin: 5rem auto;
			padding: 2rem;

			.certificatelistmainbtn {
				margin-top: 5rem;
				display: flex;
				justify-content: center;

				.btn {
					padding: 1rem 5rem;
					background-color: #1d53bf;
					color: #fff;
					border-color: transparent;
						border: 0;
					  	outline: none;
						border-radius: 1rem;
					
				}
			}
		}

	}
</style>
