<template>
  <div class="faceback">
    <homeheader></homeheader>

    <div class="facebacktmain">
      <div class="top">
        <span> 问题分类 </span>
        <div class="slectedmenu">
          <el-radio-group v-model="radio1">
            <el-radio-button :key="index" v-for="(item, index) in reason" :label="item.id">{{ item.name
            }}</el-radio-button>
          </el-radio-group>
          <!-- <el-button>火灾风险</el-button>
          <el-button>安全风险</el-button>
          <el-button>雷电风险</el-button>
          <el-button>水灾</el-button>
          <el-button>其他</el-button> -->
          <div></div>
        </div>
      </div>
      <div class="mid">
        <span> 问题描述 </span>
        <div class="midinput">
          <el-input type="textarea" placeholder="请输入内容" v-model.trim="textarea2">
          </el-input>
        </div>
      </div>
      <div class="bottom">
        <span> 上传图片 </span>

        <div class="bottombtn">
          <el-upload :before-upload="beforeAvatarUpload" :http-request="($event) => upload($event, 'prizePicture')"
            :on-success="handleAvatarSuccess" :show-file-list="false" action="#" class="avatar-uploader">
            <img style="width:10.3rem;height: 10.3rem;" v-if="prizePicture" :src="prizePicture" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <!-- <i class="el-icon-plus avatar-uploader-icon"></i> -->
          </el-upload>
        </div>
      </div>
      <div style="margin: auto; justify-content: center; display: flex">
        <!-- <el-button @click="btnclick" class="edit" type="success">查询</el-button> -->
        <button @click="SubmitEvent" class="btn">提交</button>
      </div>
    </div>

    <homefooter></homefooter>
  </div>
</template>

<script>
import homeheader from "./homeheader.vue";
import homefooter from "./homefooter.vue";
import { newfileUpload, getverification, submitfeedback } from "@/api/home";
export default {
  name: "faceback",
  props: {
    msg: String,
  },
  components: {
    homeheader: () => import("./homeheader.vue"),
    homefooter,
  },
  data() {
    return {
      textarea2: "",
      imageUrl: "",
      radio1: "",
      fileList1: [],
      prizePicture: "",
      reason: [],
      img_id: '',
      class_id: ''
    };
  },
  created() {
    this.getreason();
    window.scrollTo(0, 0)
  },
  methods: {
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/bmp'

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG、PNG、BMP 格式!')
      }
      return isJPG
    },
    handleAvatarSuccess() { },
    SubmitEvent() {

      var form = {
        'content': this.textarea2,
        'img_id': this.img_id,
        'class_id': this.radio1
      }
      console.log("form==", form);

      submitfeedback(form).then((res) => {
        if (res.code == 200) {
          this.$alert('', '提交成功', {
            callback: action => {
              this.$router.go(0)
            }
          });
        }
      });
    },
    getreason() {
      getverification().then((res) => {
        console.log(res);
        this.reason = res.data
      });
    },
    upload(e, name) {
      const loading = this.$loading({
        lock: true,
        text: "正在上传，请耐心等候！",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let file = e.file;
      console.log(file);
      let formData = new FormData();
      formData.append("file", file);
      //   formData.append("type", 5);
      newfileUpload(formData).then((res) => {
        console.log(res, "file");
        // if (res.data.code == 0) {
        loading.close();
        this.prizePicture = res.data.address;
        this.img_id = res.data.id
        // this.imageUrl.push(file.uid)
        // this.form.slideshowurls.push(res.data.data);
        // this.fileList1.push(res.data.data);
        // }
      });
    },
    //   onchangemd(e){
    // 	console.log(e.target.files)//这个就是选中文件信息
    // 	let formdata = new FormData()
    // 	Array.from(e.target.files).map(item => {
    //        console.log(item)
    //        formdata.append("file", item)  //将每一个文件图片都加进formdata
    //      })
    //      axios.post("接口地址", formdata).then(res => { console.log(res) })
    // }
  },
};
</script>

<style scoped lang="scss">
span {
  font-size: 18px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #000000;
}

.faceback {
  //   max-width: 101.25rem;
  //   margin-right: auto;
  //   margin-left: auto;
  background: #f6f7f9;

  .facebacktmain {
    margin-top: 2.75rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 74.625rem;
    height: 61rem;
    padding: 2rem;
    background: #f1f5fb;
    margin-bottom: 3.1875rem;

    .top {
      margin-top: 6rem;
      margin-left: 4.875rem;
      margin-right: 4.875rem;
      display: flex;
      // justify-items: center;
      align-items: center;

      .slectedmenu {
        // display: flex;
        flex-wrap: wrap;
        margin-left: 1.25rem;
      }
    }

    .mid {
      display: flex;
      margin-left: 4.875rem;
      margin-right: 4.875rem;
      margin-top: 4.3125rem;

      .midinput {
        margin-left: 1.25rem;
        margin-right: 4.875rem;
        width: 50rem;
        // height: 18.125rem;
      }
    }

    .bottom {
      display: flex;
      margin-left: 4.875rem;
      margin-right: 4.875rem;
      margin-top: 4.3125rem;

      .bottombtn {
        display: flex;
        // align-item: column;
        margin-left: 1.25rem;
        width: 10.3125rem;
        background: #fff;
        height: 10.3125rem;
        justify-content: center;
        align-items: center;

        .iconitem {
          display: inline-block;
          width: 36px;
          height: 36px;
          background-image: url(../assets/添加.png);
          background-position: center;
          background-size: 36px 36px;
          background-repeat: no-repeat;
          margin-right: 5px;
        }
      }
    }

    // .toptitle {
    // 	font-size: 1.75rem;
    // 	font-weight: 400;
    // 	color: #000;
    // 	font-family: PingFang SC-Bold, PingFang SC;
    // 	font-weight: bold;

    // }

    .btn {
      margin-top: 5.8125rem;
      background-color: #1d53bf;
      color: #fff;
      border-color: transparent;
      border: 0;
      width: 9.4375rem;
      height: 3.625rem;
      border-radius: 0.5rem;
      font-size: 18px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #ffffff;
    }
  }
}
</style>
