import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/security/api/login',
    method: 'post',
    data
  })
}
//忘记密码
export function forgetPassword(data) {
  return request({
    url: 'crm/api/user/forget/password',
    method: 'post',
    data
  })
}

export function getInfo() {
  return request({
    url: '/security/api/userInfo',
    method: 'get',
  })
}

export function logout() {
  return request({
    url: '/security/api/logout',
    method: 'post'
  })
}
//获取手机验证码
export function getverification(data) {
  return request({
    url: 'crm/api/user/send/verification/code',
    method: 'post',
    data
  })
}
// 获取微信unionID
export function userWeChatcode(data) {
  return request({
    url: 'crm/api/user/acquire/WeChat/code',
    method: 'post',
    data
  })
}
// 绑定微信
export function bindingWeChat(data) {
  return request({
    url: 'crm/api/user/binding/WeChat',
    method: 'post',
    data
  })
}

// 省市区
export function getArea(data) {
  return request({
    url: '/security/api/arearegion/tree',
    method: 'get',
    params: data
  })
}
// https://edu.bjxraq.com/security/api/website/redirect/login/1
export function redict(type) {
    return request({
      url: 'security/api/website/redirect/login/'+type,
      method: 'get',
    })
  }