import { login, getInfo } from '@/api/user'
// import { changeCompany  } from "@/api/index";
import {
  getToken,
  setToken,
  removeUserId,
  removeLoginPlatform,
  removeCompanyId,
  removeToken,
  getUserId,
  setUserId,
  getLoginPlatform,
  setLoginPlatform,
  getCompanyId,
  setCompanyId
} from '@/utils/auth'
import router, { resetRouter } from '@/router'

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  companyId: getCompanyId(),
  id: getUserId(),
  loginPlatform: getLoginPlatform() || 1,
  roles: [],
  bindWeiXin: false, // 是否绑定微信
  menu: [],
  permission: [],
  ws: null
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_ID: (state, id) => {
    state.id = id
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_LOGIN_PLATFORM: (state, loginPlatform) => {
    state.loginPlatform = loginPlatform
  },
  SET_COMPANY_ID: (state, companyId) => {
    state.companyId = companyId
  },
  SET_ROLE: (state, ROLE) => {
    state.role = ROLE
  },
  bindWeiXinIsShow(state, payload) {
    state.bindWeiXin = payload
  },
  GET_MENULIST: (state, menu) => {
    state.menu = menu
  },
  SET_PERMISSION: (state, permission) => {
    state.permission = permission
  },
  START_SOCKET: (state, companyId) => {
    setTimeout(() => {
        state.ws = new WebSocket(process.env.VUE_APP_BASE_SOCKET + '/security/api/server/online/' + companyId)
    }, 2000);
  },
}

const actions = {
  //登录
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      commit('SET_LOGIN_PLATFORM', userInfo.loginPlatform)
      login(userInfo).then(response => {
        if(response.code==0){
          const { data } = response
          console.log('response', response)
          console.log('data', data)
          if (data.isWeChat === 2) {
            commit('bindWeiXinIsShow', true)
            window.localStorage.setItem("userId", data.id)
            /*----判断是登录还是绑定微信-----*/
            window.localStorage.setItem("isBindWeixin", 1)
          } else {
            commit('SET_ID', data.id)
            setUserId(data.id)
            commit('SET_LOGIN_PLATFORM', data.loginPlatform)
            setLoginPlatform(data.loginPlatform)
            commit('SET_COMPANY_ID', data.companyId)
            setCompanyId(data.companyId)
            commit('SET_TOKEN', 'admin-token')
            setToken(data.token)
            // console.log('data.id', this.id)
            // console.log('data.loginPlatform', this.loginPlatform)
            // console.log('data.companyId', data)
            window.sessionStorage.setItem('companyId', data.companyId)
            window.sessionStorage.setItem('loginPlatform', data.loginPlatform)
          }
        }
        resolve(response)
      }).catch(error => {
        console.log('error', error)
        reject(error)
      })
    })
  },


  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      // getInfo(state.token).then(response => {
      getInfo().then(response => {
        console.log("------路由-----")
        console.log(response.data.menuList)
        console.log("------路由-----")
        let data = response.data
        data.roles = [state.loginPlatform + '']
        window.sessionStorage.setItem('companyId', data.companyId)
        commit('SET_ROLE', data.role) 
        commit('GET_MENULIST', data.menuList)
        commit('SET_NAME', data.nickName || data.username)
        commit('SET_AVATAR', data.headUrl)
        commit('SET_ROLES', data.menuList)
        commit('SET_PERMISSION', data.permission)

        commit('START_SOCKET', data.companyId)
        
        //  `role` '用户角色：0普通员工  1平台超级管理员，2平台普通管理员 3企业超级管理员 4企业普通管理员 5个人管理员
        let pl = ['', 1 , 1, 2, 2, 3]
        commit('SET_LOGIN_PLATFORM', pl[data.role])

        resolve(data)
      }).catch(error => {
        reject(error)
      })
      // const data = {
      //   introduction: 'I am a super administrator',
      //   name: 'Super Admin',
      //   roles: ['admin']
      // }
      // if (!data) {
      //   reject('Verification failed, please Login again.')
      // }
      // const { roles, name, avatar, introduction } = data
      //
      // if (!roles || roles.length <= 0) {
      //   reject('getInfo: roles must be a non-null array!')
      // }
      //
      // commit('SET_ROLES', roles)
      // commit('SET_NAME', name)
      // commit('SET_AVATAR', avatar)
      // resolve(data)
    })
  },



  // 退出
  resetLogin({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('SET_ID', undefined)
      commit('SET_LOGIN_PLATFORM', undefined)
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resetRouter()
      dispatch('tagsView/delAllViews', null, { root: true })
      resolve(true)
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)
    // console.log('走不走啊')
    const { roles } = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
