<template>
	<div id="app">
		<!-- <homepage></homepage> -->
		<router-view></router-view>
	</div>
</template>

<script>
	import homepage from "./components/homepage.vue";

	export default {
		name: "App",
		components: {
			homepage,
		},
		created() {
			// 页面加载时设置根元素字体大小
			this.setRem();
			// 监听窗口大小变化，并动态设置根元素字体大小
			window.addEventListener("resize", () => {
				this.setRem();
			});
			if (this._isMobile()) {
			      console.log("手机端");
			       // window.localtion.open("http://mobile-safeweb.shaokepei.top/")
				   // window.open("https://mobile-safeweb.shaokepei.top/");
			    } else {
			      console.log("pc端");
			    }
		},

		methods: {
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
					)
				return flag;
			},
			setRem() {
				var rem = document.documentElement.clientWidth / 120;
				document.documentElement.style.fontSize = rem + "px";
			},
		},

	};
</script>

<style>
	body {
		margin: 0;
	}
</style>