export default {
  //导出结构说明 一个模块的状态管理应该是 包含state（必选）、mutations（必选）、getter（可选）、action（可选异步）等对象
  state: {
      //控制侧边栏收起，这个读者可以忽略
      isCollapse: false,
      //用于显示面包屑当前所在页面，这个读者可以忽略
      currentMenu: null,
      // tabList用来存放已经点击打开的页面菜单，即面包板
      tabsList: [
      ]
  },
  mutations: {
      //可以直接操作状态，方法的收个参数为当前状态对象，第二个可选参数为载荷，一般为对象
      isCollapseMenu(state) {
          state.isCollapse = !state.isCollapse
      },
      //这个函数是这样运行的，首先在菜单栏目里点击某一个菜单选型，跳转后，全局方法调用来执行下面函数
      //并传入点击变量item，在这个函数体里面有两个修改state的内容（全局变量） 包括应用于顶部面包屑的currentMenu的值
      //以及页面标签页【用一个对象数组来存储标签内容】，先判断是否已经存在，如果不存在着就push进去·，否则不做操作【三目运算】
      selectMenu(state, val) {
          //val.name==='home'?(state.currentMenu=null):state.currentMenu=val
          if (val.name == 'workerhome') {
              state.currentMenu = null;
          }else{
              state.currentMenu=val
              //新增tabslist
              let result=state.tabsList.findIndex(item=>item.name==val.name)
              result===-1?state.tabsList.push(val):''
          }
      },
      //用作标签页的删除
      closeTag(state,val){
          let result=state.tabsList.findIndex(item=>item.name===val.name)
          state.tabsList.splice(result,1)
      },
      // 删除所有,用于在用户推出时清空状态
      deleteAll(state){
          state.tabsList=[];
      },
  },
  getter:{
      // getter中我们可以定义一个函数，这个函数我们用来修改state中的值的，函数接收一个参数state，
      //这个state参数就是当前的state对象，通过这个参数可以加工state中的数据，加工好return出去，以供组件中使用
      gettersChange(state){
          return state.tabsList;
      }
  },
  action:{
      //有需要异步操作的异步请求的时候需要在这个对象中添加方法
  }
}
0
