<template>
	<div ref="main_left" class="main_left">
		<div class="kg" @click="open">
			<i v-if="main_hover" class="el-icon-caret-left"></i>
			<i v-else class="el-icon-caret-right"></i>
		</div>

		<div class="tree_content">
			<!--  -->
			<div class="main_left_title">
				<div v-if="title">{{ title }}</div>
				<slot v-else name="treeTitle"></slot>
				<div class="icons">
					<div class="over" @click="packUp">
						<!-- 收起 -->
						<!-- <img src="@/assets/img/tree_07.png" alt /> -->
					</div>
				</div>
			</div>

			<!-- <div class="search">
				<el-input v-show="true" :placeholder="placeholder || '请输入分类名称'" suffix-icon="el-icon-search" v-model="treeInput"></el-input>

				<div></div>
			</div> -->

			<el-tree :height="550" :data="treeData" :props="defaultProps" node-key="id" @node-click="onCurrentTree"
				:expand-on-click-node="false" :filter-node-method="filterNode" ref="tree"
				:default-expanded-keys="defaultShowNodes">
				<span class="custom-tree-node" :id="data.id" slot-scope="{ node, data }">
					<div :class="['tree-left', operateId == data.id ? 'on' : '']">
						<span class="el-tree-node-icon">
							<!-- <span class="plus" v-if="data.children && data.children.length">
								<img src="@/assets/img/tree_10.png" alt />
							</span>
							<span class="minus" v-if="data.children && data.children.length">
								<img src="@/assets/img/tree_09.png" alt />
							</span>-->
							<!-- 展开 -->
							<span class="minus" style="display: none;">
								<div v-if="data.children.length > 0">
									<!-- 一级 有子级 -->
									<div v-if="node.level <= 2">
										<img v-if="operateId == data.id" src="@/assets/img/tree_new/you_zhan_on.png"
											alt />
										<img v-else src="@/assets/img/tree_new/you_zhan_off.png" alt />
									</div>
									<!-- 二级 有子级 -->
									<div v-else>
										<img v-if="operateId == data.id" src="@/assets/img/tree_new/er_zhan_on.png"
											alt />
										<img v-else src="@/assets/img/tree_new/er_zhan_off.png" alt />
									</div>
								</div>
								<!-- 一级 没有子级 -->
								<div v-if="data.children.length <= 0 && node.level <= 2">
									<img v-if="operateId == data.id" src="@/assets/img/tree_new/off_on.png" alt />
									<img v-else src="@/assets/img/tree_new/off_off.png" alt />
								</div>
								<!-- 二级 没有子级 -->
								<div v-else-if="data.children.length <= 0 && node.level > 2">
									<img v-if="operateId == data.id" src="@/assets/img/tree_new/er_meiyou_on.png" alt />
									<img v-else src="@/assets/img/tree_new/er_meiyou_off.png" alt />
								</div>
							</span>
							<!-- 未展开 -->
							<span class="plus">
								<!-- 一级 没有子级 -->
								<div v-if="data.children.length <= 0 && node.level <= 2">
									<img v-if="operateId == data.id" src="@/assets/img/tree_new/off_on.png" alt />
									<img v-else src="@/assets/img/tree_new/off_off.png" alt />
								</div>
								<!-- 二级 没有子级 -->
								<div v-else-if="data.children.length <= 0 && node.level > 2">
									<img v-if="operateId == data.id" src="@/assets/img/tree_new/er_meiyou_on.png" alt />
									<img v-else src="@/assets/img/tree_new/er_meiyou_off.png" alt />
								</div>
								<div v-else>
									<!-- 一级 有子级 -->
									<div v-if="node.level <= 2">
										<img v-if="operateId == data.id" src="@/assets/img/tree_new/you_on.png" alt />
										<img v-else src="@/assets/img/tree_new/you_off.png" alt />
									</div>
									<!-- 二级 有子级 -->
									<div v-else>
										<img v-if="operateId == data.id" src="@/assets/img/tree_new/er_shou_on.png"
											alt />
										<img v-else src="@/assets/img/tree_new/er_shou_off.png" alt />
									</div>
								</div>
							</span>
						</span>
						<div>
							<el-tooltip class="item" effect="dark" :content="node.label" placement="top">
								<p class="em-tree-text">
									<span v-if="node.level != 2 && !data.children" class="em-tree-text-radio"></span>
									{{ node.label }}
								</p>
							</el-tooltip>
						</div>
					</div>
				</span>
			</el-tree>
		</div>
	</div>
</template>

<script>
	import {
		aboutlink
	} from '@/api/home'

	let typeList = []

	function getNodeParent(node) {
		const name = [node.label]

		function traverse(node) {
			// 只获取顶级节点和当前节点
			if (!name.includes(node.label) && node.level == 2) {
				name.push(node.label)
			}
			if (node.level == 4) {
				name.push('......')
			}
			if (node.parent && node.level > 1) {
				traverse(node.parent)
			}
		}
		traverse(node)

		return name
			.map((e) => {
				return `【${e}】`
			})
			.reverse()
			.join(' —> ')
		// return name.reverse().join(' —> ')
	}

	export default {
		props: {
			type: {
				required: false,
			},
			title: {
				type: String,
				required: false,
			},
			placeholder: {
				type: String,
				required: false,
			},
			options: {
				type: Object,
				required: false,
				default: function() {
					return {}
				},
			},
		},
		data() {
			return {
				treeData: [],
				treeInput: '',

				isCurrent: null,
				defaultProps: {
					children: 'children',
					label: 'title',
				},
				main_hover: false,
				search_show: false,

				defaultShowNodes: ['', 0],

				tree: {
					getNodeParent, // 获取点击节点父级
				},

				operateId: null,

				option: {
					reqParam: {}, // 请求体

					shell: true, // 自义定父级
					shellObj: {
						title: '全部',
						id: 0,
					}, // 父级object

					addMaxLevel: 9999, // 添加最大层级 超过层级的关闭添加按钮

					url: null, // 自义定请求，menu=false
					methods: 'post', // 自义定请求getpost

					treeNameJoin: ' -> ', // treeName 的分割字符
				},

				loading: false, // 节流
				// role: this.$store.state.user.role,
			}
		},
		activated() {
			this.getTree()
		},
		mounted() {
			this.getTree()
		},
		watch: {
			treeInput(val) {
				if (val) {
					val = val.replace(/\s/g, '')
				}
				this.$refs.tree.filter(val)
			},
			'options.url'() {
				this.$forceUpdate()
				this.getTree()
			},
			type() {
				this.$forceUpdate()
				this.getTree()
			},
		},
		methods: {
			// 获取数据
			async getTree(parentId = '', focusId) {
				// prop：type; option: 默认值; options: 传参
				// 1.合并参数

				// 2.区分查询接口
				var data
				var {
					data
				} = await aboutlink()

				// 3.父级
				// if (option.shell) {
					// this.treeData = [
					// 	{
					// 		...this.option.shellObj,
					// 		children: data,
					// 	},
					// ]
				// } else {
				this.treeData = data

				focusId ? '' : focusId = data[0]?.id
				// }

				if (focusId || id) {
					this.defaultShowNodes.push(parentId)
					this.$nextTick(() => {
						this.$refs.tree.setCurrentKey(focusId)

						let dT = document.getElementById(focusId).offsetTop // dom距离顶部的高度
						document.documentElement
							.querySelector('.el-tree')
							.scrollTo(0, dT)
					})
				}
			},
			onCurrentTree(data, node) {
				let selectTreeName = this.tree.getNodeParent(node)

				this.operateId = data.id

				this.defaultShowNodes.push(data.id)
				this.$emit(
					'handlerCurrent',
					Object.assign({}, data, {
						selectTreeName,
					})
				)
			},
			filterNode(value, data) {
				if (!value) return true
				return data.name.indexOf(value) !== -1
			},
			open() {
				this.main_hover = !this.main_hover
				this.$emit('widthChange', this.main_hover)
			},
			// 一键收起
			packUp() {
				const nodes = this.$refs.tree.store._getAllNodes()
				nodes.forEach((item) => {
					item.level != 1 ? (item.expanded = false) : ''
				})
			},
		},
	}
</script>

<style scoped lang="scss">
	.main_left {
		background-color: #ffffff;
		border-radius: 0 0 5px 5px;
		display: flex;
		flex-direction: column;
		// align-items: center;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		margin-right: 20px;
		width: 390px;
	}

	::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}

	::-webkit-scrollbar-track {
		background-color: #fff;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 5px;
		background-color: rgba(0, 0, 0, 0.1);
	}

	/*定义两端按钮的样式*/
	/* ::-webkit-scrollbar-button {
  height: 10px;
  background-color:#f8b700;
} */

	/*定义右下角汇合处的样式*/
	::-webkit-scrollbar-corner {
		background: f8b700;
	}

	::v-deep .main_left_title {
		width: 100%;
		padding: 15px;
		font-weight: 500;
		color: #494949;
		font-size: 14px;
		line-height: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #efefef;

		.icons {
			display: flex;
			align-items: center;

			img {
				margin-left: 10px;
				cursor: pointer;
			}

			i {
				color: #76b2f9;
				font-size: 20px;
				margin-left: 10px;
				cursor: pointer;
			}

			.el-upload {
				display: inline-block;
				height: 13px;
			}
		}
	}

	.main_left>>>.el-input {
		width: calc(100% - 32px);
		/* margin: 0 16px; */
	}

	.main_left>>>.el-input__inner {
		height: 36px;
	}

	::v-deep .el-tree {
		width: 100%;
		height: 78vh;
		margin-top: 10px;
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 500;
		color: #222c32;
		overflow-y: auto;
		padding: 0 15px;

		>>>.el-tree-node__content:hover {
			background-color: #eff6ff;
		}

		.is-current {
			>.el-tree-node__content {
				color: #1b80f9;
				background: #eff6ff;
				// border-right: 2px solid #0f89eb;
			}

			.em-tree-text-radio {
				background-color: #1b80f9;
			}
		}

		.el-tree-node__content {
			height: auto !important;
			position: relative;
		}
	}

	.custom-tree-node {
		flex: 1;
		display: flex;
		/* justify-content: space-between; */
		align-items: center;
		overflow: hidden;
		transform: translateX(-20px);

		>div:first-child {
			display: flex;
			align-items: center;
		}
	}

	.custom-tree-node>>>.el-input {
		height: 32px;
	}

	.custom-tree-node>>>.el-input__inner {
		height: 32px;
	}

	.el-tree>>>.el-icon-caret-right:before {
		content: '';
		font-size: 18px;
		color: #92999d;
	}

	.el-tree>>>.el-tree-node__expand-icon.is-leaf:before {
		color: transparent;
	}

	.el-tree>>>.el-tree-node>.el-tree-node__children {
		overflow: visible;
	}

	.tree-left {
		flex: 3;
		overflow: hidden;

		&.on {}

		>div {
			display: flex;
			align-items: center;
			overflow: hidden;
			font-size: 16px;

			>div {
				flex: 1;
				overflow: hidden;
			}
		}
	}

	.operate {
		flex: 1;
		width: 150px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		font-size: 25px;

		i {
			font-size: 16px;
			margin-left: 10px;
			color: #42a5f5;
		}

		.el-dropdown-link {
			padding: 0 0 0 20px;
			cursor: pointer;
		}
	}

	.dialog-body-qiyewp {
		padding: 21px 25px 0 25px;
		border: 1px solid #dcdcdc;
	}

	.em-tree-text {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		margin: 10px 0;
		font-size: 14px;
	}

	.em-tree-text-radio {
		width: 5px;
		height: 5px;
		border-radius: 50%;
		margin-right: 3px;
		background-color: #222c32;
		display: inline-block;
		vertical-align: middle;
	}

	.tree_content {
		position: relative;
		width: 262px;
		// overflow-y: auto;

		&.open {
			width: 100%;
		}
	}

	.kg {
		position: absolute;
		top: 45%;
		right: -14px;
		z-index: 99;
		width: 14px;
		height: 87px;
		background: #ffffff;
		border-radius: 0px 15px 15px 0px;
		opacity: 1;
		border: 1px solid #c3c3c3;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
	}

	.kg2 {
		right: 0;
	}

	.unitDepartment {
		display: flex;
		// flex-direction: column;
		align-items: center;

		.name {
			font-size: 18px;
			color: #101010;
			text-align: center;
			margin-bottom: 12px;
		}

		.search {
			width: 450px;
			margin-bottom: 12px;
		}

		.el-tree {
			width: 100%;

			.icons {
				display: none;

				i {
					display: inline-block;
					color: #86c2fd;
					margin-left: 5px;
				}
			}

			::v-deep .is-current {
				>.el-tree-node__content {
					.label {
						color: #86c2fd;
					}

					.icons {
						display: inline-block;
					}
				}
			}
		}
	}

	.formBtns {
		display: flex;
		align-items: center;
		justify-content: center;

		.el-button {
			width: 80px;
			height: 35px;
			border-radius: 4px;
			border: 1px solid #dddddd;
			font-size: 11px;
			color: #333333;
			background-color: white;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	::v-deep .rank {
		text-align: center;
		padding: 20px 0;
		font-size: 20px;
	}

	::v-deep .el-tree-node__content:hover {
		.el-tree-node-icon {
			color: #1a8dff;

			i {
				color: #1a8dff !important;
			}
		}
	}

	.el-tree-node-icon {
		>span {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			border-radius: 2px;
		}

		color: #b4b6bd;
		font-size: 10px;
		margin-right: 10px;
		min-width: 20px;
		min-height: 20px;

		>i {
			transform: scale(0.8);
		}

		img {
			width: 20px;
			height: 20px;
		}
	}

	::v-deep .el-tree-node__expand-icon {
		color: transparent !important;
		position: relative !important;
		z-index: 2 !important;
		opacity: 0 !important;
	}

	::v-deep .minus {
		display: none !important;
	}

	::v-deep .is-expanded>.el-tree-node__content>.custom-tree-node>.tree-left {
		.plus {
			display: none !important;
		}

		.minus {
			display: flex !important;
		}
	}

	.el-dropdown-menu__item {
		font-size: 14px;
		color: #666666;
		display: flex;
		align-items: center;

		img {
			margin-right: 12px;
			width: 12px;
			height: 12px;
		}

		&:hover {
			background: #eff6ff;
			color: #1b80f9;
		}
	}

	.over {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		color: #999999;
		cursor: pointer;

		img {
			width: 10px;
			height: 5px;
			margin-left: 5px;
		}
	}

	.search {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 17px;
		padding: 0 15px;

		::v-deep .el-input {
			height: 28px;
			border-radius: 4px 4px 4px 4px;

			.el-input__inner {
				height: 100%;
			}

			.el-input__suffix {
				display: flex;
				align-items: center;
			}
		}

		.el-dropdown-link {
			width: 2px;
			height: 10px;
			cursor: pointer;
			// background-image: url('../../../assets/img/tree_11.png');
			background-repeat: no-repeat;
			background-size: 2px 10px;
			display: block;
			padding-left: 16px;
			background-position: right center;

			&:hover {
				// background-image: url('../../../assets/img/tree_04.png');
			}
		}
	}

	::v-deep .el-dialog {
		border-radius: 10px;
		box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
	}
</style>