<template>
  <div class="successfulcase">
    <homeheader style="width: 100%"></homeheader>
    <div class="Navigation">
      <i @click="clickhome" class="el-icon-house"></i>
      <p>></p>
      <p>成功案例</p>
    </div>
    <div class="problemmainView">
      <div class="problemleft">
        <div
          v-for="(item, index) in titlearry"
          :key="index"
          @click="itemclick(index)"
          :class="['problemleftitem', slected == index ? 'on' : '']"
        >
          <img
            style="width: 14px; height: 15px; display: block"
            :src="slected == index ? img : img1"
            alt=""
          />
          {{ item.title }}
        </div>
      </div>
      <div class="problemview">
        <div class="listview" v-for="(item,index) in right">
          <div class="listitemview" v-if="index%2==0">
            <img
              class="listitemview_img"
              style="margin-right: 3.125rem"
              :src="item.img"
              alt=""
            />
            <div class="listitemview_content">
              <div class="top">
                <span>{{item.title}}</span>
              </div>
              <div class="bottom">
                <div class="bottom_content">
                  <span class="title">项目名称：</span>{{item.name}}
                </div>
                <div class="bottom_content">
                  <span class="title">选用材料：</span
                  >{{item.material}}
                </div>
              </div>
            </div>
          </div>
          <div class="listitemview" v-if="index%2!=0">
            <div class="listitemview_content" style="margin-right: 3.125rem">
              <div class="top">
                <span>{{item.title}}</span>
              </div>
              <div class="bottom">
                <div class="bottom_content">
                  <span class="title">项目名称：</span>{{item.name}}
                </div>
                <div class="bottom_content">
                  <span class="title">选用材料：</span
                  >{{item.material}}
                </div>
              </div>
            </div>
            <img class="listitemview_img" :src="item.img" alt="" />
          </div>
          <!-- <div class="listitemview">
            <img
              class="listitemview_img"
              src="../assets/back.jpg"
              alt=""
              style="margin-right: 3.125rem"
            />
            <div class="listitemview_content">
              <div class="top">
                <span>中国尊</span >
              </div>
              <div class="bottom">
                <div class="bottom_content">
                  <span class="title">项目名称：</span>中国尊项目
                </div>
                <div class="bottom_content">
                  <span class="title">选用材料：</span
                  >选用材料：JX-405自粘性聚合物改性沥青卷材自粘聚合物改性沥青防水卷材
                  自粘卷材N II PE 2.0 15
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div class="pagination">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="page.offset"
            layout=" sizes, prev, pager, next, jumper"
            :total="page.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <homefooter style="width: 100%"></homefooter>
  </div>
</template>

<script>
import homefooter from "./homefooter.vue";
import img from "@/assets/choose.png";
import img1 from "@/assets/choose1.png";

import { case_info } from "@/api/home";

export default {
  name: "successfulcase",
  props: {
    msg: String,
  },
  components: {
    homeheader: () => import("./homeheader.vue"),
    homefooter,
  },
  data() {
    return {
      page: {
        offset: 10,
        page: 0,
        total: 0,
      },

      img: img,
      img1: img1,
      currentPage: 1,
      slected: "",
      titlearry: ["经典工程", "案例类型", "地铁机场", "市镇交通", "最多几个字"],
      right:[]
    };
  },
   created() {
    this.getdata();
  },
  methods: {
    getdata() {
      case_info(this.page).then((res) => {
        console.log(res);
        this.titlearry = res.data.left
        this.right = res.data.data
        this.page.total = res.data.count
      });
    },
    // btnclick() {
    // 	this.$router.go(-1)
    // },
    // currentChange() {}
    // handleCurrentChange() {},
    itemclick(index) {
      console.log(index);
      this.slected = index
      this.page.id = this.titlearry[index].id
      this.getdata()
    },
    clickhome() {
      this.$router.replace("/");
    },
    handleSizeChange(e) {
    	console.log('111111111',e);
    	this.page.offset=e
    	this.getdata()
    },
    handleCurrentChange(e) {
    	console.log('2222222222',e);
    	this.page.page=e
    	this.getdata()
    },
  },
};
</script>

<style scoped lang="scss">
.successfulcase {
  // max-width: 120rem;
  margin-right: auto;
  margin-left: auto;
  background: #f6f7f9;

  .Navigation {
    margin-right: auto;
    margin-left: auto;
    max-width: 101.25rem;
    display: flex;
    height: 57px;
    align-items: center;

    p {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      margin-left: 5px;
    }
  }

  .problemmainView {
    max-width: 101.25rem;
    display: flex;
    margin-right: auto;
    margin-left: auto;

    .problemleft {
      padding-top: 19px;
      width: 390px;
      height: 417px;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #191919;
      background: #fff;
      margin-right: 9px;

      .problemleftitem {
        display: flex;
        height: 47px;
        padding-left: 49px;
        line-height: 47px;
        text-align: left;
        position: relative;
        cursor: pointer;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #191919;

        img {
          margin-top: auto;
          margin-bottom: auto;
          margin-right: 4px;
        }

        &.on {
          background-color: #266fe8;
          color: #fff;
        }
      }
    }

    .problemview {
      // margin-top: 1rem;
      background: #feffff;
      width: 75.625rem;
      margin-bottom: 1.25rem;

      .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #feffff;
        margin-top: 2rem;
        padding-bottom: 2.875rem;
      }

      .listview {
        .listitemview {
          display: flex;
          padding-left: 2.8125rem;
          padding-right: 2.8125rem;

          .listitemview_img {
            width: 28.375rem;
            height: 20.3125rem;
            padding: 3.4375rem 0;
          }

          .listitemview_content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 3.4375rem 0;
            width: 44.4375rem;

            .top {
              display: flex;
              justify-content: space-between;

              span {
                font-size: 40px;
                font-family: PingFang SC-Bold, PingFang SC;
                font-weight: bold;
                color: #191919;
                display: block;
                line-height: 4.5625rem;
              }

              .top_img {
                width: 4.625rem;
                height: 4.625rem;
              }
            }

            .bottom {
              .bottom_content {
                font-size: 16px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #191919;

                span {
                  color: #999;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
